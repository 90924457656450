import { Typography } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import img from "../../../../assets/img/svgs/img.svg";
import plus from "../../../../assets/img/svgs/plus.svg";
import Select from "react-select";
import ModelSS from "./ModelSS";
import SupportService from "../../../services/support.service";
import withLoader from "../../../layout/loader/withLoader";
import Loader from "../../../layout/loader/loader";
const options = [
  {
    value: "InProgress",
    label: "InProgress",
    color: "#7373c9",
    icon: "fa fa-hourglass-half",
  },
  { value: "Pending", label: "Pending", color: "#fd6074", icon: "fa fa-clock" },
  {
    value: "Resolved",
    label: "Resolved",
    color: "#15A552",
    icon: "fa fa-check",
  },
];
const filteredOptions = options.filter(
  (option) => option.value !== "Pending" && option.value !== "InProgress"
);
const filteredOptions2 = options.filter((option) => option.value !== "Pending");
const Option = ({ innerProps, label, data }) => (
  <div
    {...innerProps}
    className="text-white d-flex align-items-center justify-content-center m-1"
    style={{ backgroundColor: data.color, borderRadius: "3px", height: 28 }}
  >
    <i className={data.icon} style={{ marginRight: "5px" }}>
      {" "}
    </i>{" "}
    {label}
  </div>
);
const SupportDetails = () => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [selectedOption2, setSelectedOption2] = useState(options[1]);
  const [status, setStatus] = useState("");
  const [show, setshow] = useState(false);
  const [screenshot, setScreenshots] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const handelDialogOpen = () => {
    setshow(true);
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const Details = async (data) => {
    try {
      setDataLoader(true);
      const response = await SupportService.OneTicket(state?.data?.id);
      setTicketData(response?.data?.data);
      setStatus(response?.data?.data.status);
      setDataLoader(false);
    } catch (e) {
      console.log("e", e);
    }
  };
  const handleStatus = async (selectedOption) => {
    setStatus(selectedOption?.value);
    setSelectedOption(selectedOption);
    const response = await SupportService.TicketAcion(
      ticketData.id,
      selectedOption?.value
    );
    Details();
  };
  const handleStatus2 = async (selectedOption) => {
    setStatus(selectedOption?.value);
    setSelectedOption2(selectedOption);
    const response = await SupportService.TicketAcion(
      ticketData.id,
      selectedOption?.value
    );
    Details();
  };

  // useEffect(() => {
  //     setStatus(ticketData?.status)
  // }, [state])
  useEffect(() => {
    Details();
  }, []);

  const handleScreenshotModal = async (state) => {
    handelDialogOpen();
    const images = state?.data?.screenshot || [];
    setScreenshots(images);
  };
  return (
    <Fragment>
      {show && (
        <ModelSS
          show={show}
          onHide={() => setshow(false)}
          screenshot={screenshot}
        />
      )}

      {/* <!-- Page Header --> */}
      <div className="page-header">
        <div className="d-flex align-items-center justify-content-start">
          <Button
            style={{ borderRadius: "50%", marginRight: "8px" }}
            onClick={() => navigate("/support")}
          >
            <i className="d-block fe fe-arrow-left"></i>
          </Button>
          <h2 className="main-content-title tx-24 mg-b-0">
            Support ({state?.data?.username})
          </h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col md={10}>
          <Card className="custom-card">
            <Card.Body>
              {/* {dataLoader ? <Loader /> : */}
              <Row>
                <div>
                  <div className="border-bottom mb-3">
                    <FormGroup className="form-group">
                      <Row className="row-sm">
                        <div className="d-flex">
                          <div
                            className="me-auto d-flex justify-content-start"
                            style={{
                              fontWeight: 600,
                              fontSize: "20px",
                              color: "#023220",
                            }}
                          >
                            TICKET DETAIL
                          </div>
                          <div>
                            {status === "InProgress" ? (
                              <Select
                                defaultValue={selectedOption}
                                isSearchable={false}
                                onChange={(selectedOption) =>
                                  handleStatus(selectedOption)
                                }
                                options={filteredOptions}
                                components={{ Option }}
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                    width: 125,
                                    borderRadius: 5,
                                    backgroundColor: "#7373c9",
                                    padding: 0,
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#7373c9",
                                    borderRadius: 5,
                                    color: "white",
                                    height: "35px",
                                    minHeight: "0px",
                                    cursor: "pointer",
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: "white",
                                    overflow: "visible",
                                    marginLeft: "0px",
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                  }),
                                  menuList: (provided) => ({
                                    ...provided,
                                    padding: 0,
                                    gap: 2,
                                    cursor: "pointer",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    marginTop: "4px",
                                  }),
                                }}
                                formatOptionLabel={(option) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      className={option.icon}
                                      style={{ marginRight: "6px" }}
                                    ></i>
                                    {option.label}
                                  </div>
                                )}
                              />
                            ) : status === "Pending" ? (
                              <Select
                                defaultValue={selectedOption2}
                                isSearchable={false}
                                onChange={(selectedOption) =>
                                  handleStatus2(selectedOption)
                                }
                                options={filteredOptions2}
                                components={{ Option }}
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                    width: 125,
                                    borderRadius: 5,
                                    backgroundColor: "#F1911D",
                                    padding: 0,
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: "#F1911D",
                                    borderRadius: 5,
                                    color: "white",
                                    height: "35px",
                                    minHeight: "0px",
                                    cursor: "pointer",
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: "white",
                                    overflow: "visible",
                                    marginLeft: "0px",
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                  }),
                                  menuList: (provided) => ({
                                    ...provided,
                                    padding: 0,
                                    gap: 2,
                                    cursor: "pointer",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    marginTop: "4px",
                                  }),
                                }}
                                formatOptionLabel={(option) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      className={option.icon}
                                      style={{ marginRight: "6px" }}
                                    ></i>
                                    {option.label}
                                  </div>
                                )}
                              />
                            ) : (
                              <div className="d-flex">
                                {status === "Resolved" && (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-success text-white"
                                    style={{
                                      width: "125px",
                                      height: "35px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                      gap: 3,
                                      fontSize: "13px",
                                      fontWeight: "bold",
                                      textAlign: "center",
                                      cursor: "default",
                                    }}
                                  >
                                    <i
                                      style={{ paddingRight: "3px" }}
                                      className="fa fa-check"
                                    ></i>{" "}
                                    Accepted
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </Row>
                    </FormGroup>
                  </div>
                  <div>
                    <div className="row">
                      <FormGroup className="form-group">
                        <Row className="row-sm  support-page">
                          <Col md={2} className="d-flex align-items-center">
                            <Form.Label className="text-dark mt-0">
                              {"Subject"} :
                            </Form.Label>
                          </Col>
                          <Col md={4}>
                            <Form.Control
                              value={ticketData?.subject}
                              disabled
                            />
                            {/* value={iconData.firstName} */}
                          </Col>
                          <Col md={2} className="d-flex align-items-center">
                            <Form.Label className="text-dark support-text">
                              {"Description"} :
                            </Form.Label>
                          </Col>
                          <Col md={4}>
                            <Form.Control
                              value={ticketData?.description}
                              disabled
                            />
                          </Col>
                        </Row>
                      </FormGroup>

                      <>
                        <FormGroup className="form-group">
                          <Row className=" row-sm support-page">
                            <Col md={2} className="d-flex align-items-center">
                              <Form.Label className="text-dark mt-0">
                                {"Ticket ID"} :
                              </Form.Label>
                            </Col>
                            <Col md={4}>
                              <Form.Control value={ticketData?.id} disabled />
                            </Col>
                            <Col md={2} className="d-flex align-items-center">
                              <Form.Label className="text-dark support-text">
                                {"Screenhots"} :
                              </Form.Label>
                            </Col>
                            <Col
                              md={4}
                              className="d-flex justify-content-start"
                            >
                              {/* <Form.Control> */}
                              {ticketData?.screenshot &&
                              ticketData?.screenshot.length > 0 ? (
                                <div
                                  className="demo-avatar-group my-auto float-end"
                                  onClick={() => handleScreenshotModal(state)}
                                >
                                  {
                                    ticketData?.screenshot &&
                                      ticketData?.screenshot.length > 0 &&
                                      ticketData?.screenshot
                                        .slice(0, 4)
                                        .map((item, index) => (
                                          <div
                                            className="main-img-user avatar-sm"
                                            key={index}
                                          >
                                            <img
                                              alt="avatar"
                                              className="rounded-circle"
                                              src={item}
                                            />
                                          </div>
                                        ))
                                    // : (
                                    //     <div className="main-img-user avatar-sm">
                                    //         <img
                                    //             alt="avatar"
                                    //             className="rounded-circle"
                                    //             src={img}
                                    //         />
                                    //     </div>
                                    // )
                                  }
                                  {ticketData?.screenshot &&
                                    ticketData?.screenshot.length > 4 && (
                                      <div className="main-img-user avatar-sm">
                                        <img
                                          alt="avatar"
                                          className="rounded-circle"
                                          src={plus}
                                        />
                                      </div>
                                    )}
                                </div>
                              ) : (
                                <p>N/A</p>
                              )}
                              {/* </Form.Control> */}
                            </Col>
                          </Row>
                        </FormGroup>
                      </>
                    </div>
                  </div>
                  <div className="border-bottom mb-3">
                    <FormGroup className="form-group">
                      <Row className="row-sm">
                        <div
                          className="me-auto d-flex justify-content-start"
                          style={{
                            fontWeight: 600,
                            fontSize: "20px",
                            color: "#023220",
                          }}
                        >
                          USER DETAIL
                        </div>
                      </Row>
                    </FormGroup>
                  </div>
                  <div className="align-items-center user-detail">
                    <div className="row">
                      <FormGroup className="form-group">
                        <Row className="row-sm">
                          <Col md={2} className="d-flex align-items-center">
                            <Form.Label className="text-dark mt-0">
                              <img
                                src={ticketData?.u_profile}
                                alt="profile"
                                height={100}
                                width={100}
                                style={{
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                  marginRight: "100px",
                                  display: "block",
                                }}
                                className="profile-image"
                              />
                            </Form.Label>
                          </Col>
                        </Row>
                      </FormGroup>
                    </div>
                    <div className="row">
                      <FormGroup className="form-group">
                        <Row className=" row-sm support-page">
                          <Col md={2} className="d-flex align-items-center">
                            <Form.Label className="text-dark mt-0">
                              {"Name"} :
                            </Form.Label>
                          </Col>
                          <Col md={4}>
                            <Form.Control
                              value={ticketData?.username}
                              disabled
                            />
                            {/* value={iconData.firstName} */}
                          </Col>
                          <Col md={2} className="d-flex align-items-center">
                            <Form.Label className="text-dark support-text">
                              {"Kyc Id"} :
                            </Form.Label>
                          </Col>
                          <Col md={4}>
                            <Form.Control value={ticketData?.kycid} disabled />
                          </Col>
                        </Row>
                      </FormGroup>

                      <>
                        <FormGroup className="form-group">
                          <Row className=" row-sm support-page">
                            <Col md={2} className="d-flex align-items-center">
                              <Form.Label className="text-dark mt-0">
                                {"Email"} :
                              </Form.Label>
                            </Col>
                            <Col md={4}>
                              <Form.Control
                                value={ticketData?.u_email}
                                disabled
                              />
                            </Col>
                            <Col md={2} className="d-flex align-items-center">
                              <Form.Label className="text-dark support-text">
                                {"Mobile Number"} :
                              </Form.Label>
                            </Col>
                            <Col md={4}>
                              <Form.Control
                                value={ticketData?.mobilenumber}
                                disabled
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                        {/* <FormGroup className="form-group">
                                                    <Row className=" row-sm">
                                                        <Col md={2} className="d-flex align-items-center">
                                                            <Form.Label className="text-dark mt-0">
                                                                {"Device"} :
                                                            </Form.Label>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Form.Control
                                                                // value={capitalizeFirstLetter(device)}
                                                                disabled />
                                                        </Col>
                                                        <Col md={2} className="d-flex align-items-center">
                                                            <Form.Label className="text-dark mt-0">
                                                                {"Plan"} :
                                                            </Form.Label>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Form.Control
                                                                // value={plan !== "" && plan !== "" && plan?.includes("Hard Copy Book + FREE PDF") ? "Hard Copy Book + FREE PDF" : plan !== "" && plan?.includes("Hard Copy Book") && plan !== "" && !plan?.includes("+ FREE PDF") ? "Hard Copy Book" : plan !== "" && plan?.includes("PDF Version") ? "PDF Version" : ""} 
                                                                disabled />
                                                        </Col>
                                                    </Row>
                                                </FormGroup> */}
                      </>
                    </div>
                  </div>
                </div>
              </Row>
              {/* } */}
            </Card.Body>
          </Card>
        </Col>
        {/* ))} */}
      </Row>
    </Fragment>
  );
};

export default withLoader(SupportDetails);
