import serverCall from "../../serverCall";

const AllFaqs = async ({ pageNumber, pageSize }) => {
    const response = await serverCall.get(`faq?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    return response;
};
const CreateFaq = async (question, answer) => {
    const response = await serverCall.post(`/faq/create-FAQ?question=${question}&answer=${answer}`)
    return response
}
const UpdateFaq = async (id, question, answer) => {
    const response = await serverCall.patch(`/faq/update-FAQ/${id}?question=${question}&answer=${answer}`)
    return response
}
const GetOne = async (id) => {
    const response = await serverCall.get(`/faq/${id}`)
    return response
}
const DeleteOne = async (id) => {
    const response = await serverCall.delete(`/faq/delete-FAQ/${id}`)
    return response
}
const FaqsService = {
    AllFaqs, CreateFaq, UpdateFaq, GetOne, DeleteOne
}
export default FaqsService