import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import FileTypeModal from "./FileTypeModal";
import Select from 'react-select';
import userDefault from '../../../../assets/img/svgs/userDefault.svg'
const UserDetails = (props) => {
    const { oneUserDetails, handleStatus, selectedOption, filteredOptions, Option, failModal, isOpenDocs, setIsOpenDocs, modalContentUrl, handleDocsModal, handleFormSubmit, docsKycId, setFailureMsg, failureMsg, selectedKycDocs, isError } = props;

    return (
        <Modal
            {...props}
            size="large"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>User Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center" style={{ paddingBottom: 'initial' }} >
                <div className="col-122">
                    <div className="card custom-card">
                        <form
                        // onSubmit={formik.handleSubmit}
                        ><div>
                                <div className="my-1">
                                    <img src={oneUserDetails?.profile === "" ? userDefault : oneUserDetails?.profile} alt="profile" width={100} height={100} style={{ borderRadius: "50%", objectFit: "cover" }} />
                                    <div className="font-weight-bold" style={{ fontSize: "large" }}>
                                        {oneUserDetails?.username}
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <Row className="mb-2">
                                        <Col xs={2} className="d-flex align-items-center">
                                            <Form.Label className="fw-bold text-start" style={{ color: "black", marginBottom: "0px" }}>
                                                {"Email"}
                                            </Form.Label>
                                        </Col>
                                        <Col xs={10}>
                                            <Form.Control value={oneUserDetails?.email} disabled />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col xs={2} className="d-flex align-items-center">
                                            <Form.Label className="fw-bold text-start" style={{ color: "black", marginBottom: "0px" }}>
                                                {"Kyc Id"}
                                            </Form.Label>
                                        </Col>
                                        <Col xs={10}>
                                            <Form.Control value={oneUserDetails?.kycid} disabled />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={2} className="d-flex align-items-center">
                                            <Form.Label className="fw-bold text-start" style={{
                                                color: "black", marginBottom: "0px",
                                                //  whiteSpace: 'nowrap'
                                            }}>
                                                {"Wallet Id"}
                                            </Form.Label>
                                        </Col>
                                        <Col xs={10}>
                                            <Form.Control value={oneUserDetails?.walletId} disabled />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={2} className="d-flex align-items-center">
                                            <Form.Label className="fw-bold text-start" style={{ color: "black", marginBottom: "0px" }}>
                                                {"Status"}
                                            </Form.Label>
                                        </Col>
                                        <Col xs={10} className="text-start">
                                            <button
                                                type="button"
                                                className={`btn btn-sm btn-${oneUserDetails?.status === "completed"
                                                    ? "success"
                                                    : oneUserDetails?.status === "under verification"
                                                        ? "warning"
                                                        : "danger"
                                                    } text-white`}
                                                style={{
                                                    // width: "130px",
                                                    height: '35px',
                                                    textAlign: "center",
                                                    cursor: "default",
                                                }}
                                            >
                                                <i style={{ paddingRight: "3px" }} class={`fa fa-${oneUserDetails?.status === "completed"
                                                    ? "check"
                                                    : oneUserDetails?.status === "under verification"
                                                        ? "clock"
                                                        : "close"
                                                    }`}></i> {oneUserDetails?.status === "reject" ? "Rejected" : oneUserDetails?.status === "under verification" ? "Under Varification" : "Approved"}
                                            </button>
                                            {/* {failModal && <ReasonField show={failModal} onHide={() => setFailModal(false)} />} */}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="pt-2">
                                    <div className="d-flex font-weight-bold" style={{ fontSize: "large" }}>
                                        Documents :
                                    </div>
                                    <div className="flex-column">
                                        {oneUserDetails && oneUserDetails?.kycDocs.map((item, index) => (
                                            <div key={index} style={{ display: "flex", justifyContent: 'space-between', marginBottom: "5px", alignItems: 'center' }}>
                                                <div className="name-docs me-2 justify-content-start" title={item.name}>{item.name.length > 30 ? item.name.slice(0, 30) + "..." : item.name}</div>
                                                {/* <div className="name-docs">
                                                    {item.status === 'Pending' ? (
                                                        <Select
                                                            defaultValue={selectedOption}
                                                            isSearchable={false}
                                                            onChange={(selectedOption) => handleStatus(selectedOption, item.id, item.name)}
                                                            options={filteredOptions}
                                                            components={{ Option }}
                                                            styles={{
                                                                container: (provided) => ({
                                                                    ...provided,
                                                                    width: 110,
                                                                    borderRadius: 5,
                                                                    backgroundColor: '#F1911D',
                                                                    padding: 0,

                                                                }),
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    backgroundColor: '#F1911D',
                                                                    borderRadius: 5,
                                                                    color: 'white',
                                                                    height: '35px',
                                                                    minHeight: '0px',
                                                                    cursor: 'pointer'
                                                                }),
                                                                singleValue: (provided) => ({
                                                                    ...provided,
                                                                    color: 'white',
                                                                    overflow: 'visible',
                                                                    marginLeft: '0px'
                                                                }),
                                                                indicatorSeparator: (provided) => ({
                                                                    ...provided,
                                                                    display: 'none'
                                                                }),
                                                                // indicatorContainer: (provided) => ({
                                                                //     ...provided,
                                                                //     padding: "0px",
                                                                //     // paddingRight: 
                                                                // }),
                                                                menuList: (provided) => ({
                                                                    ...provided,
                                                                    padding: 0,
                                                                    gap: 2,
                                                                    cursor: 'pointer'
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    marginTop: "4px"
                                                                })
                                                            }}
                                                            formatOptionLabel={(option) => (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <i className={option.icon} style={{ marginRight: '6px' }}></i>
                                                                    {option.label}
                                                                </div>
                                                            )}
                                                        />
                                                    ) : (
                                                        <div className="d-flex">
                                                            {item.status === 'APPROVED' && (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-success text-white"
                                                                    style={{
                                                                        width: "110px",
                                                                        textAlign: "center",
                                                                        cursor: "default",
                                                                    }}
                                                                >
                                                                    <i style={{ paddingRight: "3px" }} className="fa fa-check"></i> Accepted
                                                                </button>
                                                            )}
                                                            {item.status === 'REJECTED' && (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-danger text-white"
                                                                        style={{
                                                                            width: "110px",
                                                                            textAlign: "center",
                                                                            cursor: "default",
                                                                        }}
                                                                    >
                                                                        <i style={{ paddingRight: "3px" }} className="fa fa-close"></i> Rejected
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </div> */}
                                                <div className="name-docs justify-content-end gap-4 align-items-center">
                                                    {item.status.toLowerCase() === 'pending' ? (
                                                        <Select
                                                            defaultValue={selectedOption}
                                                            isSearchable={false}
                                                            onChange={(selectedOption) => handleStatus(selectedOption, item.id, item.name)}
                                                            options={filteredOptions}
                                                            components={{ Option }}
                                                            styles={{
                                                                container: (provided) => ({
                                                                    ...provided,
                                                                    width: 115,
                                                                    borderRadius: 5,
                                                                    backgroundColor: '#F1911D',
                                                                    padding: 0,

                                                                }),
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    backgroundColor: '#F1911D',
                                                                    borderRadius: 5,
                                                                    color: 'white',
                                                                    height: '35px',
                                                                    minHeight: '0px',
                                                                    cursor: 'pointer',
                                                                }),
                                                                singleValue: (provided) => ({
                                                                    ...provided,
                                                                    color: 'white',
                                                                    overflow: 'visible',
                                                                    marginLeft: '0px'
                                                                }),
                                                                indicatorSeparator: (provided) => ({
                                                                    ...provided,
                                                                    display: 'none'
                                                                }),
                                                                // indicatorContainer: (provided) => ({
                                                                //     ...provided,
                                                                //     padding: "0px",
                                                                //     // paddingRight: 
                                                                // }),
                                                                menuList: (provided) => ({
                                                                    ...provided,
                                                                    padding: 0,
                                                                    gap: 2,
                                                                    cursor: 'pointer'
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    marginTop: "4px"
                                                                })
                                                            }}
                                                            formatOptionLabel={(option) => (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <i className={option.icon} style={{ marginRight: '6px' }}></i>
                                                                    {option.label}
                                                                </div>
                                                            )}
                                                        />
                                                    ) : (
                                                        <div className="d-flex">
                                                            {item.status === 'APPROVED' && (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-success text-white"
                                                                    style={{
                                                                        width: "115px",
                                                                        height: "35px",
                                                                        display: "flex", alignItems: "center",
                                                                        justifyContent: "flex-start",
                                                                        gap: 3,
                                                                        fontSize: "13px",
                                                                        fontWeight: 'bold',
                                                                        textAlign: "center",
                                                                        cursor: "default",
                                                                    }}
                                                                >
                                                                    <i style={{ paddingRight: "3px" }} className="fa fa-check"></i> Accepted
                                                                </button>
                                                            )}
                                                            {item.status === 'REJECTED' && (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-danger text-white"
                                                                        style={{
                                                                            width: "115px",
                                                                            height: "35px",
                                                                            display: "flex", alignItems: "center",
                                                                            justifyContent: "flex-start",
                                                                            gap: 3,
                                                                            fontSize: "13px",
                                                                            fontWeight: 'bold',
                                                                            textAlign: "center",
                                                                            cursor: "default",
                                                                        }}
                                                                    >
                                                                        <i style={{ paddingRight: "3px" }} className="fa fa-close"></i> Rejected
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                    <div onClick={() => handleDocsModal(item)}
                                                        className="btn btn-primary btn-sm btn-eye"
                                                    >
                                                        <i className="fa fa-eye"></i>
                                                    </div>
                                                    {isOpenDocs && selectedKycDocs === item && <FileTypeModal show={isOpenDocs} onHide={() => setIsOpenDocs(false)} contentUrl={modalContentUrl} />}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {failModal &&
                                    <div className="mt-3">
                                        {oneUserDetails.kycDocs && oneUserDetails.kycDocs.filter(item => item.status === 'REJECTED' && item.id === docsKycId).map(item => (
                                            item.status === 'REJECTED' &&
                                            <Row key={item.id} className="mb-2">
                                                <Col xs={2} className="d-flex align-items-center">
                                                    <Form.Label className="fw-bold email-text" style={{ color: "black", marginBottom: "0px", textAlign: "start" }}>
                                                        {"Failure Message"}
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={10}>
                                                    <Form.Control type="text" placeholder={item.name} value={failureMsg[item.id] || ''} onChange={(e) => setFailureMsg({ ...failureMsg, [item.id]: e.target.value })} />
                                                    {isError &&
                                                        !failureMsg[item.id] && (
                                                            <p className="text-start error">
                                                                {"Enter the failure message!"}
                                                            </p>
                                                        )}
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                }
                            </div>
                            <div style={{ display: "flex", justifyContent: "end", alignItems: 'center' }}>
                                <Button
                                    // type="submit"
                                    onClick={handleFormSubmit}
                                    style={{
                                        marginTop: "16px",
                                    }}
                                >Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body >

        </Modal >
    );


};
export default UserDetails;