import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserService from "../../../services/user.service";
import { useEffect } from "react";
import userDefault from "../../../../assets/img/svgs/userDefault.svg";
import Select from "react-select";
import FileTypeModal from "./FileTypeModal";
import Portfolio from "./Portfolio";
import Trades from "./Trades";
import Wallet from "./Wallet";
const options = [
  { value: "PENDING", label: "Pending", color: "#F1911D", icon: "fa fa-clock" },
  {
    value: "APPROVED",
    label: "Approved",
    color: "#15A552",
    icon: "fa fa-check",
  },
  {
    value: "REJECTED",
    label: "Rejected",
    color: "#fd6074",
    icon: "fa fa-close",
  },
];
const filteredOptions = options.filter((option) => option.value !== "PENDING");
const Option = ({ innerProps, label, data }) => (
  <div
    {...innerProps}
    className="text-white d-flex align-items-center justify-content-center m-1"
    style={{ backgroundColor: data.color, borderRadius: "3px", height: 28 }}
  >
    <i className={data.icon} style={{ marginRight: "5px" }}>
      {" "}
    </i>{" "}
    {label}
  </div>
);
const UserDetailsPage = () => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [docsKycId, setDocsKycId] = useState("");
  const [failureMsg, setFailureMsg] = useState({});
  const [failModal, setFailModal] = useState(false);
  const [selectedKycDocs, setSelectedKycDocs] = useState(null);
  const [isOpenDocs, setIsOpenDocs] = useState(false);
  const [modalContentUrl, setModalContentUrl] = useState("");
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState("");
  const [tab, setTab] = useState("Profile");
  const location = useLocation();
  const userName = location?.state?.firstName + " " + location?.state?.lastName;
  const { id } = useParams();
  const navigate = useNavigate();
  const handleStatus = async (selectedOption, kycId) => {
    setDocsKycId(kycId);
    setSelectedOption(selectedOption);
    const updatedKycDocs = data.kycDocs.map((item) => {
      if (item.id === kycId) {
        return {
          ...item,
          status: selectedOption.value,
          failureMsg:
            selectedOption.value === "REJECTED" ? failureMsg[kycId] || "" : "", // Set failureMsg only for rejected documents
        };
      }
      return item;
    });
    setData((prevState) => ({
      ...prevState,
      kycDocs: updatedKycDocs,
    }));
    if (selectedOption && selectedOption.value === "REJECTED") {
      setFailModal(true);
      if (!failureMsg[kycId]) {
        setFailureMsg({
          ...failureMsg,
          [kycId]:
            data.kycDocs.find((item) => item.id === kycId)?.failureMsg || "",
        });
      }
    }
  };
  const handleDocsModal = (item) => {
    setSelectedKycDocs(item);
    setIsOpenDocs(true);
    const fileUrl = item.document;
    setModalContentUrl(fileUrl);
  };
  const userDetails = async () => {
    const response = await UserService.GetOneUserDetails(id);
    const result = response.data.data;
    setData(result);
  };

  const handleFormSubmit = async () => {
    try {
      const isAnyFailureMsgEmpty = data.kycDocs.some(
        (item) => item.status === "REJECTED" && !failureMsg[item.id]
      );
      if (isAnyFailureMsgEmpty) {
        // Display an error message or prevent form submission
        setIsError(true);
        console.error("Enter the failure message for all rejected documents.");
        return;
      }
      const response = await UserService.KycDocsStatus({
        userId: id,
        kycDocs: data.kycDocs.map((item) => ({
          kycId: item.id,
          status: item.status,
          failureMessage:
            item.status === "REJECTED" ? failureMsg[item.id] || "" : "",
        })),
      });
      // setIsError(true)
      // handleDetailsModal(userId)
    } catch (e) {
      console.log("e", e);
    }
    setFailModal(false);
    // AllUsers();
  };
  useEffect(() => {
    userDetails();
  }, []);
  const handletab = (tab) => {
    setTab(tab);
  };
  return (
    <>
      <div className="page-header">
        <div className="d-flex align-items-center justify-content-start">
          <Button
            style={{ borderRadius: "50%", marginRight: "8px" }}
            onClick={() => navigate("/users")}
          >
            <i className="d-block fe fe-arrow-left"></i>
          </Button>
          <h2 className="main-content-title tx-24 mg-b-0">
            {userName}-{tab}
          </h2>
        </div>
      </div>
      <div>
        <Tabs
          defaultActiveKey={tab}
          className="nav panel-tabs  main-nav-line"
          fill
          onSelect={(key) => handletab(key)}
        >
          <Tab
            className="border border-top-0 px-0"
            eventKey="Profile"
            title="Profile"
          >
            <Row className="row-sm">
              <Col xs={12} sm={12} md={10} lg={7}>
                <Card className="custom-card mt-3">
                  <Card.Body>
                    <Row>
                      <div>
                        <div className="border-bottom mb-3">
                          <FormGroup className="form-group">
                            <Row className="row-sm">
                              <div className="d-flex">
                                <div
                                  className="me-auto d-flex justify-content-start"
                                  style={{
                                    fontWeight: 600,
                                    fontSize: "20px",
                                    color: "#023220",
                                  }}
                                >
                                  PROFILE
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    className={`btn btn-sm btn-${
                                      data?.status === "completed"
                                        ? "success"
                                        : data?.status === "under verification"
                                        ? "warning"
                                        : "danger"
                                    } text-white`}
                                    style={{
                                      width: "150px",
                                      height: "35px",
                                      textAlign: "center",
                                      cursor: "default",
                                    }}
                                  >
                                    <i
                                      style={{ paddingRight: "3px" }}
                                      class={`fa fa-${
                                        data?.status === "completed"
                                          ? "check"
                                          : data?.status ===
                                            "under verification"
                                          ? "clock"
                                          : "close"
                                      }`}
                                    ></i>{" "}
                                    {data?.status === "reject"
                                      ? "Rejected"
                                      : data?.status === "under verification"
                                      ? "Under Varification"
                                      : "Approved"}
                                  </button>
                                </div>
                              </div>
                            </Row>
                          </FormGroup>
                        </div>
                        <div className="align-items-center user-detail gap-4">
                          <div className="text-center imgSpace">
                            <img
                              src={
                                data?.profile === ""
                                  ? userDefault
                                  : data?.profile
                              }
                              alt="profile"
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%",
                              }}
                            />
                          </div>
                          <div className="row width">
                            <div
                              className="input-flex padding"
                              style={{ justifyContent: "space-evenly" }}
                            >
                              <div className="margin">
                                <div
                                  className="input-flex"
                                  style={{
                                    alignItems: "center",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <Form.Label className="text-dark mt-0">
                                    {"Name:"}
                                  </Form.Label>
                                  <Form.Control
                                    // style={{ marginLeft: '8px' }}
                                    value={data?.username}
                                    disabled
                                    className="form-input-width"
                                  />
                                </div>
                                <div
                                  className="input-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <Form.Label className="text-dark d-flex gap-1 mt-0">
                                    {"Email:"}
                                  </Form.Label>
                                  <Form.Control
                                    // style={{ marginLeft: '10px' }}
                                    value={data?.kycid}
                                    disabled
                                    className="form-input-width2"
                                  />
                                </div>
                              </div>
                              <div className="ms-kyc">
                                <div
                                  className="input-flex"
                                  style={{
                                    alignItems: "center",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <Form.Label className="text-dark mt-0 d-flex">
                                    {"Kyc"}
                                    <span style={{ paddingLeft: "4px" }}>
                                      Id:
                                    </span>
                                  </Form.Label>
                                  <Form.Control
                                    // style={{ marginLeft: '8px' }}
                                    value={data?.username}
                                    disabled
                                    className="form-input-width"
                                  />
                                </div>
                                <div
                                  className="input-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <Form.Label className="text-dark d-flex gap-1 mt-0">
                                    {"Email:"}
                                  </Form.Label>
                                  <Form.Control
                                    // style={{ marginLeft: '10px' }}
                                    value={data?.kycid}
                                    disabled
                                    className="form-input-width2"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <FormGroup>
                            <div
                              className="d-flex font-weight-bold"
                              style={{ fontSize: "large" }}
                            >
                              Documents :
                            </div>
                            <div className="flex-column">
                              {data &&
                                data?.kycDocs.map((item, index) => (
                                  <div key={index} className="profile-docs-res">
                                    <div
                                      className="name-docs justify-content-start"
                                      title={item.name}
                                    >
                                      {item.name}
                                    </div>
                                    {/* item.name.length > 30 ? item.name.slice(0, 30) + "..." : */}
                                    <div className="profile-btn gap-4">
                                      {item.status.toLowerCase() ===
                                      "pending" ? (
                                        <Select
                                          defaultValue={selectedOption}
                                          isSearchable={false}
                                          onChange={(selectedOption) =>
                                            handleStatus(
                                              selectedOption,
                                              item.id,
                                              item.name
                                            )
                                          }
                                          options={filteredOptions}
                                          components={{ Option }}
                                          styles={{
                                            container: (provided) => ({
                                              ...provided,
                                              width: 115,
                                              borderRadius: 5,
                                              backgroundColor: "#F1911D",
                                              padding: 0,
                                            }),
                                            control: (provided) => ({
                                              ...provided,
                                              backgroundColor: "#F1911D",
                                              borderRadius: 5,
                                              color: "white",
                                              height: "35px",
                                              minHeight: "0px",
                                              cursor: "pointer",
                                            }),
                                            singleValue: (provided) => ({
                                              ...provided,
                                              color: "white",
                                              overflow: "visible",
                                              marginLeft: "0px",
                                            }),
                                            indicatorSeparator: (provided) => ({
                                              ...provided,
                                              display: "none",
                                            }),
                                            menuList: (provided) => ({
                                              ...provided,
                                              padding: 0,
                                              gap: 2,
                                              cursor: "pointer",
                                            }),
                                            menu: (provided) => ({
                                              ...provided,
                                              marginTop: "4px",
                                            }),
                                          }}
                                          formatOptionLabel={(option) => (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <i
                                                className={option.icon}
                                                style={{ marginRight: "6px" }}
                                              ></i>
                                              {option.label}
                                            </div>
                                          )}
                                        />
                                      ) : (
                                        <div className="d-flex">
                                          {item.status === "APPROVED" && (
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-success text-white select-btnProfile d-flex justify-content-center"
                                            >
                                              <i
                                                style={{ paddingRight: "3px" }}
                                                className="fa fa-check"
                                              ></i>{" "}
                                              Approved
                                            </button>
                                          )}
                                          {item.status === "REJECTED" && (
                                            <>
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-danger text-white select-btnProfile"
                                              >
                                                <i
                                                  style={{
                                                    paddingRight: "3px",
                                                  }}
                                                  className="fa fa-close"
                                                ></i>{" "}
                                                Rejected
                                              </button>
                                            </>
                                          )}
                                        </div>
                                      )}
                                      <div
                                        onClick={() => handleDocsModal(item)}
                                        className="btn btn-primary btn-sm btn-eye btn-res"
                                      >
                                        <i className="fa fa-eye"></i>
                                      </div>
                                      {isOpenDocs &&
                                        selectedKycDocs === item && (
                                          <FileTypeModal
                                            show={isOpenDocs}
                                            onHide={() => setIsOpenDocs(false)}
                                            contentUrl={modalContentUrl}
                                          />
                                        )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                            {failModal && (
                              <div className="mt-3">
                                {data.kycDocs &&
                                  data.kycDocs
                                    .filter(
                                      (item) =>
                                        item.status === "REJECTED" &&
                                        item.id === docsKycId
                                    )
                                    .map(
                                      (item) =>
                                        item.status === "REJECTED" && (
                                          <Row key={item.id} className="mb-2">
                                            <Col
                                              xs={2}
                                              className="d-flex align-items-center"
                                            >
                                              <Form.Label
                                                className="fw-bold email-text"
                                                style={{
                                                  color: "black",
                                                  marginBottom: "0px",
                                                  textAlign: "start",
                                                }}
                                              >
                                                {"Failure Message"}
                                              </Form.Label>
                                            </Col>
                                            <Col xs={10}>
                                              <Form.Control
                                                type="text"
                                                placeholder={item.name}
                                                value={
                                                  failureMsg[item.id] || ""
                                                }
                                                onChange={(e) =>
                                                  setFailureMsg({
                                                    ...failureMsg,
                                                    [item.id]: e.target.value,
                                                  })
                                                }
                                              />
                                              {isError &&
                                                !failureMsg[item.id] && (
                                                  <p className="text-start error">
                                                    {
                                                      "Enter the failure message!"
                                                    }
                                                  </p>
                                                )}
                                            </Col>
                                          </Row>
                                        )
                                    )}
                              </div>
                            )}
                            {data?.status === "under verification" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  // type="submit"
                                  onClick={handleFormSubmit}
                                  style={{
                                    marginTop: "16px",
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            )}
                          </FormGroup>
                        </div>
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              {/* ))} */}
            </Row>
          </Tab>
          <Tab
            className="border border-top-0 px-0"
            eventKey="Portfolio"
            title="Portfolio"
          >
            <Portfolio id={id} userData={data} tab={tab}/>
          </Tab>
          <Tab
            className="border border-top-0 px-0"
            eventKey="Trades"
            title="Trades"
          >
            <Trades id={id} tab={tab} />
          </Tab>
          <Tab
            className="border border-top-0 px-0"
            eventKey="Wallet"
            title="Wallet"
          >
            <Wallet id={id} tab={tab} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default UserDetailsPage;
