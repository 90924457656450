import React from 'react'
import { Modal } from 'react-bootstrap';
const FileTypeModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                onHide={props.onHide}
                size={props.contentUrl.endsWith('.pdf') ? "xl" : "large"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton >
                    <Modal.Title>Documet
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {props.contentUrl.endsWith('.pdf') ? (
                        <embed src={props.contentUrl} width="100%" height="700px" />
                    ) : (
                        <img src={props.contentUrl} alt="Modal Content" width="100%" />
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default FileTypeModal
