import React from "react";
import { Badge, Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";

const PropertiesEditModal = (props) => {
  const {
    formik,
    isUpdate,
    handleValuation,
    croppedImage,
    handleRentalIncome,
    handleCurrentPricePerShare,
    handlePricePerShare,
    handleCompanyShare,
    handleLockShare,
    handleMaintainceShare,
    handleFees,
    handleBidingRange,
    handleApartement,
    handleMaintaince,
    handleFullFloor,
    handleSqareFeet,
    handleRealestateNumber,
    show,
    handleDialogClose,
    handleImageChange,
    tags,
    image,
    singleImage,
    fileType,
    crop,
    zoom,
    setCrop,
    onCropComplete,
    setZoom,
    handleCropDone,
    showCropper,
    setShowCropper,
    handleTagRemove,
    handleInputChange,
    handleTagAdd,
    tagInput,
    tagList,
    previewImage,
    deleteImageHandler,
    deleteImageHandler2,
    loaderSubmit,
  } = props;

  return (
    <Modal
      {...props}
      show={show}
      onHide={() => {
        handleDialogClose();
        formik.resetForm();
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setShowCropper(false);
      }}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isUpdate ? "Update Property" : "Add Property"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center" style={{ paddingBottom: "initial" }}>
        <div className="col-122">
          <div className="card custom-card">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="col-12"
                style={{
                  overflow: "auto",
                  paddingTop: "15px",
                  marginBottom: "15px",
                  maxHeight: "650px",
                  padding: "10px 10px 0px 10px",
                }}
              >
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Name<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Properties Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.name && formik.touched.name ? (
                    <p className="text-start error">{formik.errors.name}</p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Address<span className="tx-danger">*</span>
                  </Form.Label>
                </Form.Group>
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Line1<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="line1"
                    placeholder="Address Line1"
                    value={formik.values.line1}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.line1 && formik.touched.line1 ? (
                    <p className="text-start error">{formik.errors.line1}</p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Line2<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="line2"
                    placeholder="Address Line2"
                    value={formik.values.line2}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.line2 && formik.touched.line2 ? (
                    <p className="text-start error">{formik.errors.line2}</p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Area<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="area"
                    placeholder="Area"
                    value={formik.values.area}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.area && formik.touched.area ? (
                    <p className="text-start error">{formik.errors.area}</p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Country<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="countryName"
                    placeholder="country"
                    value={formik.values.countryName}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.countryName && formik.touched.countryName ? (
                    <p className="text-start error">
                      {formik.errors.countryName}
                    </p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Valuation<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="valuation"
                    placeholder="valuation"
                    value={formik.values.valuation}
                    onChange={handleValuation}
                  />
                  {formik.errors.valuation && formik.touched.valuation ? (
                    <p className="text-start error">
                      {formik.errors.valuation}
                    </p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Rental Income - Monthly<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="rentalIncome"
                    placeholder="Rental Income"
                    value={formik.values.rentalIncome}
                    onChange={handleRentalIncome}
                  />
                  {formik.errors.rentalIncome && formik.touched.rentalIncome ? (
                    <p className="text-start error">
                      {formik.errors.rentalIncome}
                    </p>
                  ) : null}
                </Form.Group>
                {/* <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Price per share<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="priceperShare"
                    placeholder="price per share"
                    value={formik.values.priceperShare}
                    onChange={handlePricePerShare}
                  />
                  {formik.errors.priceperShare &&
                  formik.touched.priceperShare ? (
                    <p className="text-start error">
                      {formik.errors.priceperShare}
                    </p>
                  ) : null}
                </Form.Group>  */}
                {/* <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Comapny share<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="comapnyShare"
                    placeholder="Comapny share"
                    value={formik.values.comapnyShare}
                    onChange={handleCompanyShare}
                  />
                  {formik.errors.comapnyShare && formik.touched.comapnyShare ? (
                    <p className="text-start error">
                      {formik.errors.comapnyShare}
                    </p>
                  ) : null}
                </Form.Group> */}
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Percentage of maintaince<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="perOfMaintenance"
                    placeholder="Percentage of maintaince"
                    value={formik.values.perOfMaintenance}
                    onChange={handleMaintainceShare}
                  />
                  {formik.errors.perOfMaintenance &&
                  formik.touched.perOfMaintenance ? (
                    <p className="text-start error">
                      {formik.errors.perOfMaintenance}
                    </p>
                  ) : null}
                </Form.Group>
                {!isUpdate && (
                  <Form.Group
                    controlId="validationFormik101"
                    className="position-relative"
                  >
                    <Form.Label
                      style={{
                        textAlign: "start",
                        color: "#000",
                        marginTop: "15px",
                      }}
                    >
                      Lock share<span className="tx-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="lockShare"
                      placeholder="Lock share"
                      value={formik.values.lockShare}
                      onChange={handleLockShare}
                    />
                    {formik.errors.lockShare && formik.touched.lockShare ? (
                      <p className="text-start error">
                        {formik.errors.lockShare}
                      </p>
                    ) : null}
                  </Form.Group>
                )}
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Fees In Percentage<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="fees"
                    placeholder="fees"
                    value={formik.values.fees}
                    onChange={handleFees}
                  />
                  {formik.errors.fees && formik.touched.fees ? (
                    <p className="text-start error">{formik.errors.fees}</p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormik104"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Tag<span className="tx-danger">*</span>
                  </Form.Label>
                  <div>
                    <Form.Control
                      type="text"
                      value={tagInput}
                      onChange={handleInputChange}
                      onKeyDown={handleTagAdd}
                      placeholder="Add a tag (Use space to add tag)"
                      name="tag"
                    />
                    {formik.errors.tag && formik.touched.tag ? (
                      <p className="text-start error">{formik.errors.tag}</p>
                    ) : null}
                    <div style={{ marginTop: "10px", textAlign: "start" }}>
                      {isUpdate
                        ? tagList &&
                          tagList.map((tag, index) => (
                            <Badge
                              key={index}
                              pill
                              variant="primary"
                              style={{
                                marginRight: "5px",
                                marginBottom: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleTagRemove(index)}
                            >
                              {tag}
                              <span
                                className="close-icon"
                                style={{ paddingLeft: "7px" }}
                              >
                                &times;
                              </span>
                            </Badge>
                          ))
                        : tags &&
                          tags.map((tag, index) => (
                            <Badge
                              key={index}
                              pill
                              variant="primary"
                              style={{
                                marginRight: "5px",
                                marginBottom: "5px",
                              }}
                              onClick={() => handleTagRemove(index)}
                            >
                              {tag}
                              <span
                                className="close-icon"
                                style={{
                                  cursor: "pointer",
                                  paddingLeft: "7px",
                                }}
                              >
                                &times;
                              </span>
                            </Badge>
                          ))}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Biding range In Percentage
                    <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="bidingRangeInPer"
                    placeholder="Biding range"
                    value={formik.values.bidingRangeInPer}
                    onChange={handleBidingRange}
                  />
                  {formik.errors.bidingRangeInPer &&
                  formik.touched.bidingRangeInPer ? (
                    <p className="text-start error">
                      {formik.errors.bidingRangeInPer}
                    </p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Apartements<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="apartements"
                    placeholder="Apartements"
                    value={formik.values.apartements}
                    onChange={handleApartement}
                  />
                  {formik.errors.apartements && formik.touched.apartements ? (
                    <p className="text-start error">
                      {formik.errors.apartements}
                    </p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Full Floor<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="fullFloor"
                    placeholder="Full floor"
                    value={formik.values.fullFloor}
                    onChange={handleFullFloor}
                  />
                  {formik.errors.fullFloor && formik.touched.fullFloor ? (
                    <p className="text-start error">
                      {formik.errors.fullFloor}
                    </p>
                  ) : null}
                </Form.Group>
                {!isUpdate && (
                  <Form.Group
                    controlId="validationFormik101"
                    className="position-relative"
                  >
                    <Form.Label
                      style={{
                        textAlign: "start",
                        color: "#000",
                        marginTop: "15px",
                      }}
                    >
                      Square Meter<span className="tx-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="sqareFeet"
                      placeholder="square meter"
                      value={formik.values.sqareFeet}
                      onChange={handleSqareFeet}
                    />
                    {formik.errors.sqareFeet && formik.touched.sqareFeet ? (
                      <p className="text-start error">
                        {formik.errors.sqareFeet}
                      </p>
                    ) : null}
                  </Form.Group>
                )}
                {!isUpdate && (
                  <Form.Group
                    controlId="validationFormik101"
                    className="position-relative"
                  >
                    <Form.Label
                      style={{
                        textAlign: "start",
                        color: "#000",
                        marginTop: "15px",
                      }}
                    >
                      Realestate Number<span className="tx-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="realestateNumber"
                      placeholder="realestate number"
                      value={formik.values.realestateNumber}
                      onChange={handleRealestateNumber}
                    />
                    {formik.errors.realestateNumber &&
                    formik.touched.realestateNumber ? (
                      <p className="text-start error">
                        {formik.errors.realestateNumber}
                      </p>
                    ) : null}
                  </Form.Group>
                )}
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Image<span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="images"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                  />
                  {formik.errors.images && formik.touched.images ? (
                    <p className="text-start error">{formik.errors.images}</p>
                  ) : null}
                </Form.Group>
                <div className="mt-3 justify-content-around">
                  <Row>
                    {previewImage &&
                      previewImage.map((imageUrl, index) => (
                        <Col sm={4}>
                          <div
                            key={image}
                            className="image text-start  pointer img-fit-cover w-100"
                          >
                            <Button
                              onClick={() => deleteImageHandler2(imageUrl)}
                              className="position-absolute z-index-10"
                              style={{ top: "30px", left: "20px" }}
                            >
                              <i className="fas fa-trash"></i>
                            </Button>
                            <div
                              key={index}
                              className="image mt-4"
                              style={{
                                height: "167px",
                                width: "136px",
                              }}
                            >
                              <img
                                key={index}
                                src={imageUrl}
                                style={{ borderRadius: "11px" }}
                                alt="upload"
                                className="img-fit-cover"
                              />
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                  <Row>
                    {croppedImage?.map((img) => {
                      return (
                        <Col sm={4}>
                          <div
                            key={img}
                            className="image text-start  pointer img-fit-cover w-100"
                          >
                            <Button
                              onClick={() => deleteImageHandler(img)}
                              className="position-absolute z-index-10"
                              style={{ top: "30px", left: "20px" }}
                            >
                              <i className="fas fa-close"></i>
                            </Button>
                            <div
                              key={img}
                              className="image mt-4"
                              style={{
                                height: "167px",
                                width: "136px",
                              }}
                            >
                              <img
                                src={img}
                                style={{ borderRadius: "11px" }}
                                alt="upload"
                                className="img-fit-cover"
                              />
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "15px",
                    textAlign: "center",
                  }}
                >
                  {loaderSubmit ? <Spinner /> : isUpdate ? "Update" : "Submit"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PropertiesEditModal;
