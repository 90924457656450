let MENUITEMS = [];
const Email = localStorage.getItem("email");
if (Email === "admin@gmail.com") {
  MENUITEMS = [
    {
      Items: [
        {
          path: `/properties`,
          icon: "ti-home",
          type: "link",
          active: false,
          selected: false,
          title: "Properties",
        },
        {
          path: `/users`,
          icon: "ti-user",
          type: "link",
          active: false,
          selected: false,
          title: "Users",
        },
        {
          path: `/support`,
          icon: "ti-headphone-alt",
          type: "link",
          active: false,
          selected: false,
          title: "Support",
        },
        {
          path: `/faqs`,
          icon: "ti-pencil-alt",
          type: "link",
          active: false,
          selected: false,
          title: "FAQs",
        },
        {
          path: `/termsprivacy`,
          icon: "mdi mdi-book-multiple",
          type: "link",
          active: false,
          selected: false,
          title: "Terms and Privacy URL",
        },
        {
          path: `/usermanagement`,
          icon: "mdi mdi-book-multiple",
          type: "link",
          active: false,
          selected: false,
          title: "User Management",
        },
      ],
    },
  ];
} else if (Email === "superuser@gmail.com") {
  MENUITEMS = [
    {
      Items: [
        {
          path: `/store/dashboard`,
          icon: "ti-home",
          type: "link",
          active: false,
          selected: false,
          title: "Dashboard",
        },
        {
          path: `/store/categories`,
          icon: "ti-panel",
          type: "link",
          active: false,
          selected: false,
          title: "Categories",
        },
        {
          path: `/store/brands`,
          icon: "si si-badge",
          type: "link",
          active: false,
          selected: false,
          title: "Brands",
        },
        {
          path: `/store/products`,
          icon: "ti-map",
          type: "link",
          active: false,
          selected: false,
          title: "Products",
        },
        {
          path: `/store/orders`,
          icon: "ti-server",
          type: "link",
          active: false,
          selected: false,
          title: "Orders",
        },
        {
          path: `/store/sales`,
          icon: "ti-money",
          type: "link",
          active: false,
          selected: false,
          title: "Sales",
        },
        {
          path: `/store/notifications`,
          icon: "fa fa-bell",
          type: "link",
          active: false,
          selected: false,
          title: "Notification",
        },
      ],
    },
  ];
} else if (Email === "employ@gmail.com") {
  MENUITEMS = [
    {
      Items: [
        {
          path: `/team/orders`,
          icon: "ti-server",
          type: "link",
          active: false,
          selected: false,
          title: "Orders",
        },
      ],
    },
  ];
}

export { MENUITEMS };
