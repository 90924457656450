import React from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap';

const AddFaqModal = (props) => {
    const { formik, show, isUpdate, loaderFaq } = props
    return (
        <Modal
            {...props}
            show={show}
            size="large"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{isUpdate ? "Update FAQ" : "Add FAQ"}</Modal.Title>

            </Modal.Header>
            <Modal.Body className="text-center" style={{ paddingBottom: 'initial' }} >
                <div className="col-122">
                    <div className="card custom-card">
                        <form
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="col-12" style={{
                                overflow: "auto",
                                marginBottom: "15px",
                                maxHeight: "650px",
                            }} >
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                >
                                    <Form.Label
                                        style={{
                                            textAlign: "start",
                                            color: "#000",
                                        }}
                                    >
                                        {isUpdate ? "Update Question" : "Add Question"}<span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="question"
                                        as="textarea"
                                        rows={2}
                                        placeholder="Enter Question.."
                                        value={formik.values.question}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.question &&
                                        formik.touched.question ? (
                                        <p className="text-start error">
                                            {formik.errors.question}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                >
                                    <Form.Label
                                        style={{
                                            textAlign: "start",
                                            color: "#000",
                                            marginTop: "15px",
                                        }}
                                    >
                                        {isUpdate ? "Update Answer" : "Add Answer"}<span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="answer"
                                        as="textarea"
                                        rows={4}
                                        placeholder="Enter Answer.."
                                        value={formik.values.answer}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.answer &&
                                        formik.touched.answer ? (
                                        <p className="text-start error">
                                            {formik.errors.answer}
                                        </p>
                                    ) : null}
                                </Form.Group>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                <Button
                                    type="submit"
                                    style={{
                                        display: "flex",
                                        marginLeft: "auto",
                                        marginRight: "15px",
                                        textAlign: 'center',
                                    }}
                                >
                                    {loaderFaq ? <Spinner /> : (isUpdate ? "Update" : 'Submit')}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );
}

export default AddFaqModal
