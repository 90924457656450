import React, { Fragment, useEffect, useState } from "react";
import {
  MDBAccordion,
  MDBAccordionItem,
  MDBContainer,
  MDBTypography,
} from "mdb-react-ui-kit";
import { Button, Card, Col, Pagination, Row } from "react-bootstrap";
import FaqData from "../../../services/faqs.service";
import { useFormik } from "formik";
import * as yup from "yup";
import { enqueueSnackbar } from "notistack";
import AddFaqModal from "./AddFaqModal";
import useDialogState from "../../../hooks/useDialogState";
import DeleteModal from "../properties/DeleteModal";
import withLoader from "../../../layout/loader/withLoader";

const schema = yup.object().shape({
  question: yup.string().required("Please enter question"),
  answer: yup.string().required("Please enter answer"),
});
const Faqs = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [loaderFaq, setLoaderFaq] = useState(false);
  const [deleteFaq, setDeleteFaq] = useState(false);
  const [name, setName] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const pageSize = 10;

  const {
    open: show,
    handleClickOpen: handelDialogOpen,
    handleClickClose: handleDialogClose,
  } = useDialogState();

  //GetDataFunction
  const getFaqData = async () => {
    try {
      const response = await FaqData.AllFaqs({
        pageNumber: currentPage,
        pageSize: pageSize,
      });
      setData(response?.data?.data);
      setCount(response?.data?.totalFAQ)
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  useEffect(() => {
    getFaqData(currentPage);
  }, [currentPage]);

  const nextPage = (page) => {
    setCurrentPage(page);
    getFaqData(page);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const previousPage = (page) => {
    setCurrentPage(currentPage - 1);
    getFaqData(page);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  //handleDeleteFunction
  const handleDelete = (id, que) => {
    setShowDeleteModal(true);
    setName(que);
    setDeleteId(id);
  };
  const FaqDelete = async () => {
    setDeleteFaq(true)
    const response = await FaqData.DeleteOne(deleteId);
    setShowDeleteModal(false);
    getFaqData();
    setDeleteFaq(false)
    enqueueSnackbar(response.data.message, {
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  };
  //handleShowFunction
  const handleAddFaqDialog = async (id) => {
    handelDialogOpen();
    if (id) {
      setIsUpdate(true);
      const response = await FaqData.GetOne(id);
      const result = response.data.data;
      formik.setFieldValue("id", result.id);
      formik.setFieldValue("answer", result.answer);
      formik.setFieldValue("question", result.question);
    } else {
      setIsUpdate(false);
      // formik.setFieldValue("answer", "");
      // formik.setFieldValue("question", "");
    }
  };

  const submit = async (values, action) => {
    const { answer, question } = values;
    if (!values.id) {
      try {
        setLoaderFaq(true)
        const response = await FaqData.CreateFaq(question, answer);
        action.resetForm();
        getFaqData();
        setLoaderFaq(false)
        handleDialogClose();
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        enqueueSnackbar(e, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } else {
      try {
        setLoaderFaq(true)
        const response = await FaqData.UpdateFaq(values.id, question, answer);
        getFaqData(currentPage);
        formik.resetForm();
        setLoaderFaq(false)
        handleDialogClose();
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    }
    formik.resetForm();
    handleDialogClose();
  };
  //FormikFunction
  const formik = useFormik({
    initialValues: {
      answer: "",
      question: "",
    },
    onSubmit: submit,
    validationSchema: schema,
  });
  const handleFaqClose = () => {
    handleDialogClose();
    formik.resetForm()
  }
  return (
    <>
      <Fragment>
        <Row className="row-sm mt-5 page-header">
          <Col md={12} lg={12} xl={12}>
            <Card
              className="custom-card transcation-crypto"
              style={{ fullwidth: "100%", marginTop: "0px" }}
            >
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-3"
                >
                  <h2
                    className="main-content-title tx-24 mb-0"
                    style={{ marginBottom: "40px" }}
                  >
                    FAQ(Frequently Asked Questions)
                  </h2>
                  <Button
                    variant="primary"
                    type="button"
                    className="my-2 btn-icon-text"
                    onClick={() => {
                      handleAddFaqDialog("");
                    }}
                  >
                    <i className="fe fe-plus"></i> Add FAQ
                  </Button>
                </div>

                {data &&
                  data?.map((item, index) => (
                    <div
                      style={{ marginBottom: "20px", width: "100%" }}
                      key={index}
                    >
                      <MDBContainer className="" style={{ width: "100%" }}>
                        <MDBAccordion alwaysOpen initialActive={index}>
                          <MDBAccordionItem
                            style={{ whiteSpace: "pre-line" }}
                            collapseId={1}
                            headerTitle={item.question}
                          >
                            <div
                              style={{ display: "flex", marginBottom: "5px" }}
                            >
                              <MDBTypography
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "0px",
                                  whiteSpace: "pre-line",
                                }}
                                tag="h5"
                                key={index}
                              >
                                {" "}
                                {item.question}
                              </MDBTypography>

                              <Button
                                style={{
                                  marginLeft: "auto",
                                  marginTop: "-5px",
                                  marginBottom: "auto",
                                }}
                                onClick={() => handleAddFaqDialog(item.id)}
                                className="btn btn-primary btn-sm"
                              >
                                <i className="fa fa-pencil"></i>
                              </Button>

                              <Button
                                variant="danger"
                                style={{
                                  marginTop: "-5px",
                                  marginBottom: "auto",
                                  marginLeft: "5px",
                                }}
                                onClick={() =>
                                  handleDelete(item.id, item.question)
                                }
                                className="btn btn-danger btn-sm"
                              >
                                <i className="fa fa-trash" />
                              </Button>
                            </div>
                            {item.answer}
                          </MDBAccordionItem>
                        </MDBAccordion>
                      </MDBContainer>
                    </div>
                  ))}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {count > 10 && (
          <Pagination
            className="pagination-circle mb-0 ms-lg-3"
            style={{ justifyContent: "center" }}
          >
            <Pagination.Item
              onClick={() => previousPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <i className="fas fa-angle-double-left"></i>
            </Pagination.Item>
            {Array.from({ length: Math.ceil(count / pageSize) }).map(
              (_, index) => {
                const pageNumber = index + 1;
                return (
                  <Pagination.Item
                    onClick={() => nextPage(pageNumber)}
                    key={pageNumber}
                    active={pageNumber === currentPage}
                  >
                    {pageNumber}
                  </Pagination.Item>
                );
              }
            )}
            <Pagination.Item
              onClick={() => nextPage(currentPage + 1)}
              disabled={currentPage === Math.ceil(count / pageSize)}

            >
              <i className="fas fa-angle-double-right"></i>
            </Pagination.Item>
          </Pagination>
        )}
      </Fragment>
      {/* <!-- Modal OF AddFaqQuestion Start --> */}
      {show && (
        <AddFaqModal
          show={show}
          onHide={handleFaqClose}
          getFaqData={getFaqData}
          formik={formik}
          isUpdate={isUpdate}
          loaderFaq={loaderFaq}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          formik={formik}
          FaqDelete={FaqDelete}
          name={name}
          deleteId={deleteId}
          mixdata="faq"
          deleteFaq={deleteFaq}
        />
      )}
    </>
  );
};

export default withLoader(Faqs);
