import React from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'

const RestoreModal = (props) => {
    const { handleRestoreProperty, archivePropertyName, AllProperties, restoreLoader } = props
    const handleDelete = (e) => {
        e.preventDefault();
        handleRestoreProperty()
        AllProperties()
    }
    return (
        <>
            <Modal
                {...props}
                size="large"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton >
                    <Modal.Title>Restore
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <i className={`icon ion-ios-checkmark-circle-outline tx-100 tx-success mg-t-20`}></i>

                    <h4 className='tx-success tx-semibold mg-b-20'>Restore</h4>
                    <p className="mg-b-20 mg-x-20 ">
                        Are you sure want to restore <b>
                            {archivePropertyName}
                        </b>?
                    </p>

                    <Button
                        type="button"
                        onClick={(e) => { handleDelete(e) }}
                        variant='success'
                    >
                        {restoreLoader ? <Spinner /> : 'Restore'}
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RestoreModal
