import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLogin from "./app/authentication/login";
import MatxLayout from "./app/component/maxLayout";
import NotFound from "./app/layout/sessions/NotFound";
import Properties from "./app/component/admin/properties/Properties";
import Users from "./app/component/admin/users/Users";
import UserDetails from "./app/component/admin/users/UserDetailsModal";
import ViewProperty from "./app/component/admin/properties/ViewProperty";
// import Documents from "./app/component/admin/users/Documents";
import Support from "./app/component/admin/support/Support";
import SupportDetails from "./app/component/admin/support/SupportDetails";
import Faqs from "./app/component/admin/faqs/Faqs";
import TermsPrivacy from "./app/component/admin/termsprivacy/TermsPrivacy";
import { elements } from "chart.js";
import UserDetailsPage from "./app/component/admin/users/UserDetailsPage";
import ShareDetails from "./app/component/admin/properties/ShareDetails";
import RecycleProperty from "./app/component/admin/properties/RecycleProperty";
import UserManagement from "./app/component/admin/usermanagement/UserManagement";

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}
const Email = localStorage.getItem("email");

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MatxLayout /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        index: true,
        element: (
          <>
            {Email === "admin@gmail.com" && (
              <RedirectionWrapper to="/properties/" />
            )}
            {Email === "superuser@gmail.com" && (
              <RedirectionWrapper to="/store/dashboard/" />
            )}
            {Email === "employ@gmail.com" && (
              <RedirectionWrapper to="/team/orders/" />
            )}
          </>
        ),
      },
      {
        path: "/properties/",
        element: <Properties />,
      },
      {
        path: "/properties/details/:propertyId/",
        element: <ViewProperty />,
      },
      {
        path: "/properties/recycle/",
        element: <RecycleProperty />,
      },
      {
        path: "/properties/sharedetails/:shareNumber/",
        element: <ShareDetails />,
      },
      {
        path: "/users/",
        element: <Users />,
      },
      {
        path: "/users/details/:id",
        element: <UserDetailsPage />,
      },
      // {
      //   path: "/users/details/:Id/",
      //   element: <UserDetails />,
      // },
      // {
      //   path: "/users/details/docs/",
      //   element: <Documents />,
      // },
      {
        path: "/support",
        element: <Support />,
      },
      {
        path: "/support/details/:Id/",
        element: <SupportDetails />,
      },
      {
        path: "/faqs",
        element: <Faqs />,
      },
      {
        path: "/termsprivacy",
        element: <TermsPrivacy />,
      },
      {
        path: "/usermanagement",
        element: <UserManagement />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? (
      <AuthLogin />
    ) : (
      <>
        {Email === "admin@gmail.com" && (
          <RedirectionWrapper to="/properties/" />
        )}
        {Email === "superuser@gmail.com" && (
          <RedirectionWrapper to="/store/dashboard/" />
        )}
        {Email === "employ@gmail.com" && (
          <RedirectionWrapper to="/team/orders/" />
        )}
      </>
    ),
  },
  { path: "*", element: <NotFound /> },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
