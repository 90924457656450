import React, { Fragment, useCallback } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { MdLock } from "react-icons/md";
import { FaUnlockKeyhole } from "react-icons/fa6";
import PropertiesService from "../../../services/properties.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Typography } from "antd";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/bundle";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import * as Yup from "yup";
import useDialogState from "../../../hooks/useDialogState";
import { enqueueSnackbar } from "notistack";
import { useFormik } from "formik";
import PropertiesEditModal from "./PropertiesEditModal";
import ImageModal from "./ImageModal";
import withLoader from "../../../layout/loader/withLoader";
import MainDashboard from "./MainDashboard";
import Loader from "../../../layout/loader/loader";
import { CircularProgress } from "@mui/material";
import ShareUnlockModal from "./ShareUnlockModal";
import { RevenueChart } from "../../TotalRevenueChart";

const ViewProperty = () => {
  const [propertyData, setPropertyData] = useState("");
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [tags, setTags] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [singleImage, setSingleImage] = useState("");
  const [showCropper, setShowCropper] = useState(false);
  const [croppedImage, setCroppedImage] = useState([]);
  const [newImage, setNewImage] = useState(false);
  const [fileType, setFileType] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [id, setId] = useState("");
  const [previewImage, setPreviewImage] = useState([]);
  const [croppedImageUrl, setCroppedImageUrl] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [data, setData] = useState([]);
  const [valuation, setValuation] = useState([]);
  const [tab, setTab] = useState("oneYear");
  const [graphLoader, setGraphLoader] = useState(false);
  const { propertyId } = useParams();
  const location = useLocation();
  const propertyType = location?.state;
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const {
    open: show,
    handleClickOpen: handelDialogOpen,
    handleClickClose: handleDialogClose,
  } = useDialogState();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropDone = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(singleImage, croppedAreaPixels);
      const croppedBlob = await fetch(croppedImage).then((response) =>
        response.blob()
      );

      setCroppedImageUrl(croppedImage);
      setShowCropper(false);
      setShowImageModal(false);
      handelDialogOpen();

      const croppedFile = new File([croppedBlob], "croppedImage.jpg", {
        type: "image/jpeg",
      });
      const imageUrl = URL.createObjectURL(croppedFile);
      // setSingleImage((previousImages) => [imageUrl]);
      setCroppedImage((previousImages) => [...previousImages, imageUrl]);
      // setSingleImage(croppedFile)
      formik.setFieldValue("images", croppedFile);
    } catch (e) {
      console.error(e);
    }
  }, [singleImage, croppedAreaPixels]);

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  }

  async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement("canvas");

    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      return null;
    }

    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    croppedCtx.drawImage(
      canvas,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      croppedCanvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  }

  const navigate = useNavigate();
  const initialValue = {
    name: "",
    line1: "",
    line2: "",
    area: "",
    countryName: "",
    valuation: "",
    rentalIncome: "",
    // currenPricePerShare: '',
    priceperShare: "",
    comapnyShare: "",
    maintenanceShare: "",
    fees: "",
    tag: "",
    images: null,
    bidingRangeInPer: "",
    apartements: "",
    // maintaince: '',
    fullFloor: "",
    // type: '',
    sqareFeet: "",
    // realestatenumber: ''
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter the name!"),
    line1: Yup.string().required("Please enter the address line1!"),
    area: Yup.string().required("Please enter the area!"),
    countryName: Yup.string().required("Please enter the country!"),
    valuation: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the valuation!"),
    rentalIncome: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the rental income!"),
    // currenPricePerShare: Yup.string().matches(/^[0-9]/).required('Please enter the current price!'),
    priceperShare: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the price!"),
    comapnyShare: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the company share!"),
    maintenanceShare: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the maintaince share!"),
    fees: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the fees!"),
    tag: Yup.mixed().required("Please enter the tag!"),
    bidingRangeInPer: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the biding!"),
    apartements: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the apartements!"),
    // maintaince: Yup.string().matches(/^[0-9]/).required('Please enter the maintaince!'),
    fullFloor: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the full floor!"),
    // type: Yup.string().required('Please enter the type!'),
    sqareFeet: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the sqareFeet!"),
    // realestatenumber: Yup.string().matches(/^[0-9]/).required('Please enter the realestate Number!'),
  });
  const handleFormSubmit = async (values, action) => {
    if (!values._id) {
    } else {
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("line1", values.line1);
        formData.append("line2", values.line2);
        formData.append("area", values.area);
        formData.append("countryName", values.countryName);
        formData.append("valuation", values.valuation);
        formData.append("rentalIncome", values.rentalIncome);
        formData.append("currenPricePerShare", values.currenPricePerShare);
        formData.append("priceperShare", values.priceperShare);
        formData.append("comapnyShare", values.comapnyShare);
        formData.append("maintenanceShare", values.maintenanceShare);
        formData.append("fees", values.fees);
        if (isUpdate) {
          if (tagList.length > 0) {
            tagList.forEach((item) => formData.append("tag", item));
          }
        } else {
          if (tags.length > 0) {
            tags.forEach((item) => formData.append("tag", item));
          }
        }
        formData.append("bidingRangeInPer", values.bidingRangeInPer);
        formData.append("apartements", values.apartements);
        formData.append("maintaince", values.maintaince);
        formData.append("fullFloor", values.fullFloor);
        formData.append("type", values.type);
        formData.append("sqareFeet", values.sqareFeet);
        // formData.append("realestatenumber", values.realestatenumber);
        if (newImage) {
          for (let i = 0; i < croppedImage.length; i++) {
            if (croppedImage !== null && croppedImage.length > 0) {
              const croppedBlob = await fetch(croppedImage[i]).then(
                (response) => response.blob()
              );
              const croppedFile = new File([croppedBlob], "croppedImage.jpg", {
                type: "image/jpeg",
              });
              formData.append("images", croppedFile);
            }
          }
        }
        const response = await PropertiesService.UpdateOne(
          values._id,
          formData
        );
        action.resetForm();
        setCroppedImage([]);
        DetailsOfProperty();
        setTags("");
        handleDialogClose();
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        enqueueSnackbar(e, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.log("e", e);
      }
    }
    setNewImage(false);
  };
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });
  const handleImageChange = (e) => {
    setNewImage(true);
    setShowCropper(true);
    setShowImageModal(true);
    handleDialogClose();

    const imgFile = e.currentTarget.files[0];
    formik.setFieldValue("images", imgFile);
    // setSingleImage(imgFile)
    const imageUrl = URL.createObjectURL(imgFile);
    setSingleImage((previousImages) => [imageUrl]);

    if (imgFile.type.startsWith("image/")) {
      setShowCropper(true);
      setShowImageModal(true);
      handleDialogClose();

      setFileType("image");

      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
      };
      reader.readAsDataURL(imgFile);
    }
  };
  const handleValuation = async (event, data) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("valuation", numericValue);
  };
  const handleRentalIncome = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("rentalIncome", numericValue);
  };
  const handleCurrentPricePerShare = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("currenPricePerShare", numericValue);
  };
  const handlePricePerShare = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("priceperShare", numericValue);
  };
  const handleCompanyShare = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("comapnyShare", numericValue);
  };
  const handleMaintainceShare = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("maintenanceShare", numericValue);
  };
  const handleFees = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("fees", numericValue);
  };
  const handleBidingRange = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("bidingRangeInPer", numericValue);
  };
  const handleApartement = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("apartements", numericValue);
  };
  const handleMaintaince = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("maintaince", numericValue);
  };
  const handleFullFloor = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("fullFloor", numericValue);
  };
  const handleSqareFeet = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("sqareFeet", numericValue);
  };
  // const handleRealestateNumber = async (event) => {
  //     let inputValue = event.target.value;
  //     const numericValue = inputValue.replace(/[^0-9.]/g, "");
  //     formik.setFieldValue("realestatenumber", numericValue);
  // }
  const handleDialogCloseReset = () => {
    handleDialogClose();
    formik.resetForm();
    setTags("");
    setCroppedImage([]);
  };

  const handleDialogOpen = async (id) => {
    handelDialogOpen();
    if (id) {
      setIsUpdate(true);
      const response = await PropertiesService.GetOne(id);
      const result = response.data.data;
      setId(result.id);
      formik.setFieldValue("_id", result.id);
      formik.setFieldValue("name", result.name);
      formik.setFieldValue("line1", result.add_line1);
      formik.setFieldValue("line2", result.add_line2);
      formik.setFieldValue("area", result.add_area);
      formik.setFieldValue("countryName", result.add_countryname);
      formik.setFieldValue("valuation", result.valuation);
      formik.setFieldValue("rentalIncome", result.rentalincome);
      formik.setFieldValue("fees", result.fees);
      formik.setFieldValue("currenPricePerShare", result.currenPricePerShare);
      formik.setFieldValue("priceperShare", result.pricepershare);
      formik.setFieldValue("comapnyShare", result.comapnyshare);
      formik.setFieldValue("maintenanceShare", result.maintenanceshare);
      formik.setFieldValue("bidingRangeInPer", result.bidingrangeinper);
      formik.setFieldValue("apartements", result.meta.apartements);
      formik.setFieldValue("images", result.images);
      setPreviewImage(result.images);
      formik.setFieldValue("maintaince", result.meta.maintaince);
      formik.setFieldValue("fullFloor", result.meta.fullFloor);
      formik.setFieldValue("type", result.meta.type);
      formik.setFieldValue("sqareFeet", result.meta.sqareFeet);
      // formik.setFieldValue("realestatenumber", result.realestatenumber);
      formik.setFieldValue("tag", result.tag);
      setTagList(result.tag);
      const datalist = result.tag.map((item) => item);
      setTags((prevHashtags) => [...prevHashtags, datalist.join("\n")]);
    } else {
      setIsUpdate(false);
      setTags("");
      setCroppedImage([]);
      setPreviewImage(null);
      formik.resetForm();
    }
  };
  const DetailsOfProperty = async () => {
    try {
      const response = await PropertiesService.GetOne(propertyId);
      const result = response.data.data;
      setPropertyData(result);
    } catch (e) {
      console.log("e", e);
    }
  };
  const dataGraph = async () => {
    try {
      setGraphLoader(true);
      const response = await PropertiesService.graph(tab, propertyId);
      const result = response?.data?.data;
      setValuation(result);
      setGraphLoader(false);
    } catch (e) {
      console.log("e", e);
    }
  };
  const handleTab = (tab) => {
    setTab(tab);
  };
  useEffect(() => {
    DetailsOfProperty();
  }, []);
  useEffect(() => {
    dataGraph();
  }, [tab]);

  const handleInputChange = (event) => {
    if (isUpdate) {
      const inputValue = event.target.value;
      const modifiedString = inputValue
        .split(", ")
        .map((word) => (word.startsWith("#") ? word.substring(1) : word))
        .join(", ");
      setTagInput(modifiedString);
    } else {
      const inputValue = event.target.value;
      const modifiedString = inputValue
        .split(", ")
        .map((word) => (word.startsWith("#") ? word.substring(1) : word))
        .join(", ");
      setTagInput(modifiedString);
    }
  };

  const handleTagAdd = (event) => {
    if (event.key === " " && tagInput.trim() !== "") {
      event.preventDefault();
      const modifiedString = tagInput.replace(/,(\s*)$/, "");
      if (isUpdate) {
        setTagList((prevHashtags) => [...prevHashtags, modifiedString]);
      } else {
        setTags((prevHashtags) => [...prevHashtags, modifiedString]);
        formik.setFieldValue("tag", modifiedString);
      }
      setTagInput("");
    }
  };
  const handleTagRemove = (index) => {
    if (isUpdate) {
      setTagList((prevHashtags) => prevHashtags.filter((_, i) => i !== index));
    } else {
      setTags((prevHashtags) => prevHashtags.filter((_, i) => i !== index));
    }
  };
  async function deleteImageHandler(image) {
    const dataStatus = singleImage.filter((e) => e !== image);
    setCroppedImage(dataStatus);

    const files = await Promise.all(
      dataStatus.map(async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        const fileName = url.split("/").pop();
        return new File([blob], fileName);
      })
    );
    formik.setFieldValue("images", files);
    URL.revokeObjectURL(image);
  }
  const deleteImageHandler2 = async (imageUrl) => {
    await PropertiesService.ImageDelete(encodeURIComponent(id), imageUrl);
    const response = await PropertiesService.GetOne(id);
    const result = response.data.data;
    const latestImage = result.images;
    setPreviewImage(latestImage);
  };
  const getPropertyWiseShareData = async () => {
    try {
      const response = await PropertiesService.getPropertyWiseShare(propertyId);
      setData(response.data.data);
    } catch (err) {
      console.log("get sharewise data error", err);
    }
  };
  const viewShareDetails = (shareNumber, data) => {
    navigate(`/properties/sharedetails/${shareNumber}`, {
      state: { propertyId, data, propertyType },
    });
  };
  const handleBackNavgate = () => {
    navigate("/properties");
    /* if (location?.state === "nonarchieved") {
    } else {
      navigate("/properties/recycle");
    } */
  };
  useEffect(() => {
    getPropertyWiseShareData();
  }, []);
  return (
    <>
      {/* <!-- Page Header --> */}
      {showImageModal && (
        <ImageModal
          show={showImageModal}
          onHide={() => setShowImageModal(false)}
          fileType={fileType}
          showCropper={showCropper}
          singleImage={singleImage}
          crop={crop}
          handelDialogOpen={handelDialogOpen}
          zoom={zoom}
          setCrop={setCrop}
          onCropComplete={onCropComplete}
          setZoom={setZoom}
          handleCropDone={handleCropDone}
        />
      )}
      <div className="page-header">
        <div className="d-flex align-items-center justify-content-start">
          <Button
            style={{ borderRadius: "50%", marginRight: "8px" }}
            onClick={handleBackNavgate}
          >
            <i className="d-block fe fe-arrow-left"></i>
          </Button>
          <h2 className="main-content-title tx-24 mg-b-0">Property: Details</h2>
        </div>
        <div>
          {show && (
            <PropertiesEditModal
              tags={tags}
              formik={formik}
              handleImageChange={handleImageChange}
              handleValuation={handleValuation}
              handleRentalIncome={handleRentalIncome}
              handleCurrentPricePerShare={handleCurrentPricePerShare}
              handlePricePerShare={handlePricePerShare}
              handleCompanyShare={handleCompanyShare}
              handleMaintainceShare={handleMaintainceShare}
              handleFees={handleFees}
              handleBidingRange={handleBidingRange}
              handleApartement={handleApartement}
              handleMaintaince={handleMaintaince}
              handleFullFloor={handleFullFloor}
              handleSqareFeet={handleSqareFeet}
              // handleRealestateNumber={handleRealestateNumber}
              previewImage={previewImage}
              show={show}
              handelDialogOpen={handelDialogOpen}
              handleDialogClose={handleDialogCloseReset}
              isUpdate={isUpdate}
              handleTagRemove={handleTagRemove}
              handleInputChange={handleInputChange}
              handleTagAdd={handleTagAdd}
              tagInput={tagInput}
              tagList={tagList}
              singleImage={singleImage}
              fileType={fileType}
              crop={crop}
              zoom={zoom}
              setCrop={setCrop}
              onCropComplete={onCropComplete}
              setZoom={setZoom}
              handleCropDone={handleCropDone}
              showCropper={showCropper}
              setShowCropper={setShowCropper}
              setSingleImage={setSingleImage}
              deleteImageHandler={deleteImageHandler}
              deleteImageHandler2={deleteImageHandler2}
              croppedImage={croppedImage}
            />
          )}
        </div>
      </div>
      <Row className="row-sm">
        <Col /* xs={12} sm={12} md={12} lg={12} xl={7} */>
          <Card className="custom-card productdesc">
            <Card.Body className="h-100">
              <Row>
                <Col sm={12} md={5} style={{ position: "relative" }}>
                  <Swiper
                    style={{
                      "--swiper-navigation-color": "#fff",
                      "--swiper-pagination-color": "#fff",
                    }}
                    spaceBetween={10}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                  >
                    {propertyData?.images?.map((img, index) => (
                      <SwiperSlide>
                        <img
                          src={img}
                          alt="image"
                          style={{
                            borderRadius: "10px",
                            width: "100%",
                            height: "330px",
                            objectFit: "cover",
                          }}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div
                    className="d-flex my-2"
                    style={{
                      position: "absolute",
                      top: "0px",
                      zIndex: "99",
                      right: "16px",
                    }}
                  >
                    {propertyData?.tag?.map(
                      (tag, index) =>
                        tag && (
                          <Badge
                            bg="none"
                            key={index}
                            pill
                            style={{
                              fontSize: "17px",
                              marginRight: "5px",
                              wordBreak: "break-all",
                              backgroundColor: "#e0efe9",
                              color: "#023220",
                            }}
                          >
                            {tag}
                          </Badge>
                        )
                    )}
                  </div>
                  <Swiper
                    style={{ cursor: "pointer" }}
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper pt-4"
                  >
                    {" "}
                    {propertyData?.images?.map((img, index) => (
                      <SwiperSlide>
                        <img className="slider-image" src={img} alt="image" />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Col>
                <Col sm={12} md={7}>
                  <div style={{ width: "100%" }}>
                    <div className="justify-content-start">
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="tx-18 fw-bold mt-2 mb-0">
                          {propertyData.name}, {propertyData.add_area},{" "}
                          {propertyData.add_countryname}
                        </label>
                        {location?.state === "false" && (
                          <Button
                            style={{ height: "28px" }}
                            onClick={() => handleDialogOpen(propertyId)}
                          >
                            <i className="fe fe-edit"></i>
                          </Button>
                        )}
                      </div>
                      <div>
                        <Typography className="text-muted gap-2 d-flex">
                          {propertyData.add_line1}, {propertyData.add_line2}
                        </Typography>

                        <Row className="d-flex align-items-center">
                          <Col md={6} xs={12}>
                            <Typography className="text-muted">
                              {" "}
                              {`${propertyData?.meta?.apartements} BHK`} |{" "}
                              {`${propertyData?.meta?.fullFloor} full floor`} |{" "}
                              {`${propertyData?.meta?.sqareFeet} sq.m`}
                            </Typography>
                          </Col>
                          <Col md={6} xs={12}>
                            <Typography className="text-muted gap-2 d-flex">
                              {/* <div className="text-muted">
                                Price per share:{" "}
                                <span
                                  style={{ fontWeight: "bold" }}
                                  className="text-muted"
                                >
                                  {propertyData?.pricepershare}
                                </span>
                              </div> */}
                            </Typography>
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center">
                          <Col md={6} xs={12}>
                            <Typography className="text-muted gap-2 d-flex">
                              <div className="text-muted">
                                Rental Income-Monthly:{" "}
                                <span
                                  style={{ fontWeight: "bold" }}
                                  className="text-muted"
                                >
                                  {propertyData?.rentalincome}
                                </span>
                              </div>
                            </Typography>
                          </Col>
                          <Col md={6} xs={12}>
                            <Typography className="text-muted gap-2 d-flex">
                              <div className="text-muted">
                                Biding range In Percentage:{" "}
                                <span
                                  style={{ fontWeight: "bold" }}
                                  className="text-muted"
                                >
                                  {`${propertyData?.bidingrangeinper}%`}
                                </span>
                              </div>
                            </Typography>
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center">
                          <Col md={6} xs={12}>
                            <Typography className="text-muted gap-2 d-flex">
                              <div className="text-muted">
                                RealEstate Number:{" "}
                                <span
                                  style={{ fontWeight: "bold" }}
                                  className="text-muted"
                                >
                                  {propertyData?.realestatenumber}
                                </span>
                              </div>
                            </Typography>
                          </Col>
                          <Col md={6} xs={12}>
                            <Typography className="text-muted gap-2 d-flex">
                              <div className="text-muted">
                                Fees In Percentage:{" "}
                                <span
                                  style={{ fontWeight: "bold" }}
                                  className="text-muted"
                                >
                                  {`${propertyData?.fees}%`}
                                </span>
                              </div>
                            </Typography>
                          </Col>
                        </Row>
                      </div>
                      {/* <Typography className="text-muted gap-2 d-flex">
                                                <div className="text-muted">Rental Income - Monthly : <span
                                                    style={{ fontWeight: "bold" }}
                                                    className="text-muted"
                                                >
                                                    {propertyData?.rentalincome}
                                                </span>
                                                </div>
                                            </Typography> */}
                      {/* <div className="d-flex my-2">
                        {propertyData?.tag?.map(
                          (tag, index) =>
                            tag && (
                              <Badge
                                bg="none"
                                key={index}
                                pill
                                style={{
                                  fontSize: "17px",
                                  marginRight: "5px",
                                  wordBreak: "break-all",
                                  backgroundColor: "#e0efe9",
                                  color: "#023220",
                                }}
                              >
                                {tag}
                              </Badge>
                            )
                        )}
                      </div> */}
                    </div>
                  </div>
                  {/* <div
                                        style={{
                                            background: "#F6F7F9",
                                            borderRadius: "6px",
                                            padding: "8px",
                                            gap: "8px",
                                        }}
                                    > */}
                  <div className="pt-3">
                    <Typography className="fw-bold tx-18">
                      Valuation - <span>{propertyData.valuation}</span> KD
                    </Typography>
                    <Row className="d-flex align-items-center">
                      <Col xs={9} md={6} className="paading-res">
                        <Typography className="text-muted">
                          Annulised return
                        </Typography>
                      </Col>
                      <Col xs={3} md={6} className="paading-res">
                        <Typography className="fw-bold tx-16">
                          {propertyData?.annualReturn
                            ? propertyData?.annualReturn !== undefined
                              ? `${propertyData?.annualReturn?.toFixed(2)}%`
                              : "N/A"
                            : `${0}%`}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center">
                      <Col xs={9} md={6} className="paading-res">
                        <Typography className="text-muted">
                          Maintainance Share
                        </Typography>
                      </Col>
                      <Col xs={3} md={6} className="paading-res">
                        <Typography className="fw-bold tx-16">
                          {`${propertyData.perOfMaintenance}%`}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center">
                      <Col xs={9} md={6} className="paading-res">
                        <Typography className="text-muted">
                          Price per share
                        </Typography>
                      </Col>
                      <Col xs={3} md={6} className="paading-res">
                        <Typography className="fw-bold tx-16">
                          {" "}
                          {propertyData.rental !== undefined
                            ? `${propertyData.pricepershare.toFixed(2)} KD`
                            : "N/A"}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center">
                      <Col xs={9} md={6} className="paading-res">
                        <Typography className="text-muted">
                          Rental income per share
                        </Typography>
                      </Col>
                      <Col xs={3} md={6} className="paading-res">
                        <Typography className="fw-bold tx-16">
                          {propertyData?.rentalIncomePerShare
                            ? propertyData?.rental !== undefined
                              ? `${propertyData?.rentalIncomePerShare?.toFixed(
                                  2
                                )} KD`
                              : "N/A"
                            : `${0}%`}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center">
                      <Col xs={9} md={6} className="paading-res">
                        <Typography className="text-muted">
                          Company own share
                        </Typography>
                      </Col>
                      <Col xs={3} md={6} className="paading-res">
                        <Typography className="fw-bold tx-16">
                          {propertyData?.companycount}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="d-flex align-items-center">
                      <Col xs={9} md={6} className="paading-res">
                        <Typography className="text-muted">
                          Total share
                        </Typography>
                      </Col>
                      <Col xs={3} md={6} className="paading-res">
                        <Typography className="fw-bold tx-16">
                          {propertyData?.comapnyshare}
                        </Typography>
                      </Col>
                    </Row>

                    <Row className="d-flex align-items-center">
                      <Col xs={9} md={6} className="paading-res">
                        <Typography className="text-muted">
                          Lock Share
                        </Typography>
                      </Col>
                      <Col xs={3} md={6} className="paading-res">
                        <Typography className="fw-bold tx-16 ">
                          {propertyData.lockshare}
                          <FaUnlockKeyhole
                            style={{
                              marginRight: "5px",
                              marginLeft: "5px",
                              marginBottom: "4px",
                              cursor: "pointer",
                            }}
                            onClick={handleOpenModal}
                          />
                        </Typography>
                      </Col>
                    </Row>
                  </div>
                  {/* </div> */}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        {/* <Col xs={12} sm={12} md={12} ls={12} xl={5}>
          <Card className="custom-card overflow-hidden">
            <Card.Header className="border-bottom-0">
              <label className="main-content-title tx-18 fw-bold mg-b-0">
                GRAPH
              </label>
            </Card.Header>
            <Card.Body className="ps-12">
              <div className="chart-dropshadow2 ht-300">
                {graphLoader ? (
                  <div className="chart-dropshadow2 ht-300 d-flex justify-content-center align-items-center">
                    <CircularProgress />{" "}
                  </div>
                ) : (
                  <div className="chart-dropshadow2 ht-300">
                    <div className="chart-dropshadow2 ht-300">
                      <MainDashboard valuation={valuation} tab={tab} />
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-3 graph-tab">
                <Tabs
                  defaultActiveKey={tab}
                  fill
                  onSelect={(key) => handleTab(key)}
                  className="nav panel-tabs  main-nav-line justify-content-center"
                >
                  <Tab
                    className="border border-top-0 p-0"
                    eventKey="oneYear"
                    title="One Year"
                  ></Tab>
                  <Tab
                    className="border border-top-0 p-0"
                    eventKey="fiveYear"
                    title="Five Year"
                  ></Tab>
                </Tabs>
              </div>
            </Card.Body>
          </Card>
        </Col> */}
        {/* ))} */}
      </Row>
      <Row>
        <Col md={6}>
          <Card className="custom-card overflow-hidden">
            <Card.Header className="border-bottom-0">
              <label className="main-content-title tx-18 fw-bold mg-b-0">
                GRAPH
              </label>
            </Card.Header>
            <Card.Body className="ps-12">
              <div className="chart-dropshadow2 ht-300">
                {graphLoader ? (
                  <div className="chart-dropshadow2 ht-300 d-flex justify-content-center align-items-center">
                    <CircularProgress />{" "}
                  </div>
                ) : (
                  <div className="chart-dropshadow2 ht-300">
                    <div className="chart-dropshadow2 ht-300">
                      <MainDashboard valuation={valuation} tab={tab} />
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-3 graph-tab">
                <Tabs
                  defaultActiveKey={tab}
                  fill
                  onSelect={(key) => handleTab(key)}
                  className="nav panel-tabs  main-nav-line justify-content-center"
                >
                  <Tab
                    className="border border-top-0 p-0"
                    eventKey="oneYear"
                    title="One Year"
                  ></Tab>
                  <Tab
                    className="border border-top-0 p-0"
                    eventKey="fiveYear"
                    title="Five Year"
                  ></Tab>
                </Tabs>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <RevenueChart propertyId={propertyId} />
        </Col>
      </Row>

      <Table responsive>
        <tbody>
          {data
            .reduce((chunks, share, index) => {
              if (index % 10 === 0) {
                chunks.push(data.slice(index, index + 10));
              }
              return chunks;
            }, [])
            .map((chunk, rowIndex) => (
              <tr key={rowIndex} style={{ border: "1px solid" }}>
                {chunk.map((share, cellIndex) => (
                  <td
                    onClick={() =>
                      viewShareDetails(share.shareNumber, data, propertyType)
                    }
                    style={{
                      border: "1px solid #000",
                      cursor: "pointer",
                      backgroundColor:
                        share.status === "company"
                          ? "#023220"
                          : share.status === "open_order"
                          ? "#46C646"
                          : "#C30000",
                      textAlign: "center",
                      color: share.status === "open_order" ? "#000" : "#ffffff",
                    }}
                    key={cellIndex}
                  >
                    {share.isLocked && (
                      <MdLock
                        style={{ marginRight: "7px", marginBottom: "4px" }}
                        size={18}
                      />
                    )}
                    {share.shareNumber}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
      <ShareUnlockModal
        show={showModal}
        handleClose={handleCloseModal}
        propertyId={propertyId}
        handleOpenModal={handleOpenModal}
        lockShare={propertyData.lockshare}
        getPropertyWiseShareData={getPropertyWiseShareData}
        DetailsOfProperty={DetailsOfProperty}
      />
    </>
  );
};
export default withLoader(ViewProperty);
