import React, { useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/bundle"
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { useLocation } from 'react-router-dom';
const ModelSS = (props) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const location = useLocation()
    return (
        <Modal
            {...props}
            // className='screenshort-modals'
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Images
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4 text-center" >
                <Row>
                    <Col sm={12}>
                        <Swiper
                            style={{
                                "--swiper-navigation-color": "#fff",
                                "--swiper-pagination-color": "#fff",
                            }}
                            spaceBetween={10}
                            navigation={true}
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper2"
                        >
                            {props?.screenshot?.map((img, index) => (
                                <SwiperSlide>
                                    <img className='slider-main-image' src={img} alt="image" style={{
                                        borderRadius: '10px', width: '100%',
                                        // height: '330px',
                                        objectFit: 'cover',
                                    }} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <Swiper
                            style={{ cursor: 'pointer' }}
                            onSwiper={setThumbsSwiper}
                            spaceBetween={10}
                            slidesPerView={4}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper pt-4 secound-part"
                        >
                            {" "}
                            {props?.screenshot?.map((img, index) => (
                                <SwiperSlide className='h-50'>
                                    <img className='slider-image' src={img} alt="image" />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Col>
                </Row>
            </Modal.Body >

        </Modal >
    );
}

export default ModelSS
