import React, { useCallback } from "react";
import withLoader from "../../../layout/loader/withLoader";
import { Badge, Button, Card, Col, Pagination, Row } from "react-bootstrap";
import { useState } from "react";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import useDialogState from "../../../hooks/useDialogState";
import { enqueueSnackbar } from "notistack";
import Loader1 from "../../../../assets/img/svgs/loader.svg";
import PropertiesEditModal from "./PropertiesEditModal";
import DeleteModal from "./DeleteModal";
import PropertiesService from "../../../services/properties.service";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import ImageModal from "./ImageModal";
import RestoreModal from "./RestoreModal";

const MergeProperties = ({ type }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [page, setPage] = useState(1);
  const [newImage, setNewImage] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [previewImage, setPreviewImage] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const navigate = useNavigate();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [image, setImage] = useState("");
  const [tagList, setTagList] = useState([]);
  const [singleImage, setSingleImage] = useState("");
  const [zoom, setZoom] = useState(1);
  const [fileType, setFileType] = useState("");
  const [croppedImageUrl, setCroppedImageUrl] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState([]);
  const [id, setId] = useState("");
  const [loaderSubmit, setLoaderSubmit] = useState(false);
  const [deleteProperty, setDeleteProperty] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [archiveId, setArchiveId] = useState("");
  const [archivePropertyName, setArchivePropertyName] = useState("");
  const [restoreLoader, setRestoreLoader] = useState(false);
  const limit = 6;
  const {
    open: show,
    handleClickOpen: handelDialogOpen,
    handleClickClose: handleDialogClose,
  } = useDialogState();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const handleCropDone = useCallback(async () => {
    try {
      const croppedImages = await getCroppedImg(singleImage, croppedAreaPixels);
      const croppedBlob = await fetch(croppedImages).then((response) =>
        response.blob()
      );

      setCroppedImageUrl(croppedImages);
      setShowCropper(false);
      setShowImageModal(false);
      handelDialogOpen();

      const croppedFile = new File([croppedBlob], "croppedImage.jpg", {
        type: "image/jpeg",
      });
      const imageUrl = URL.createObjectURL(croppedFile);
      // setSingleImage((previousImages) => [imageUrl]);
      if (croppedImage.length < 1) {
        setCroppedImage([imageUrl]);
      } else {
        setCroppedImage((previousImages) => [...previousImages, imageUrl]);
      }
      // setSingleImage(croppedFile)
      formik.setFieldValue("images", croppedFile);
    } catch (e) {
      console.error(e);
    }
  }, [singleImage, croppedAreaPixels]);

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);
    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  }

  async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement("canvas");

    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      return null;
    }

    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    croppedCtx.drawImage(
      canvas,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      croppedCanvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  }
  const initialValue = {
    name: "",
    line1: "",
    line2: "",
    area: "",
    countryName: "",
    valuation: "",
    rentalIncome: "",
    priceperShare: "",
    // comapnyShare: '',
    lockShare: "",
    perOfMaintenance: "",
    fees: "",
    tag: "",
    images: null,
    bidingRangeInPer: "",
    apartements: "",
    fullFloor: "",
    sqareFeet: "",
    realestateNumber: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter the name!"),
    line1: Yup.string().required("Please enter the address line1!"),
    line2: Yup.string().required("Please enter the address line2!"),
    area: Yup.string().required("Please enter the area!"),
    // city: Yup.string().required('Please enter the city!'),
    countryName: Yup.string().required("Please enter the country!"),
    valuation: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the valuation!"),
    rentalIncome: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the rental income!"),
    // priceperShare: Yup.string()
    //   .matches(/^[0-9]/)
    //   .required("Please enter the price!"),
    // comapnyShare: Yup.string().matches(/^[0-9]/).required('Please enter the company share!'),
    lockShare: isUpdate
      ? ""
      : Yup.string()
          .matches(/^[0-9]/)
          .required("Please enter the lock share!"),
    perOfMaintenance: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the maintaince share!"),
    fees: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the fees!"),
    // tag: Yup.string().required('Please enter the tag!'),
    tag: Yup.mixed().required("Please enter the tag!"),
    images: Yup.mixed().required("Please select an image!"),
    bidingRangeInPer: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the biding!"),
    apartements: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the apartements!"),
    fullFloor: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the full floor!"),
    sqareFeet: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the sqare meter!"),
    realestateNumber: isUpdate
      ? ""
      : Yup.string()
          .matches(/^[0-9]/)
          .required("Please enter the realestate Number!"),
  });
  const handleDialogOpen = async (id) => {
    handelDialogOpen();
    if (id) {
      setIsUpdate(true);
      const response = await PropertiesService.GetOne(id);
      const lockResponse = await PropertiesService.LockProperty(id);
      const result = response.data.data;
      console.log("maintenanceshare", result?.perOfMaintenance);
      setId(result.id);
      formik.setFieldValue("_id", result.id);
      formik.setFieldValue("name", result.name);
      formik.setFieldValue("line1", result.add_line1);
      formik.setFieldValue("line2", result.add_line2);
      formik.setFieldValue("area", result.add_area);
      formik.setFieldValue("countryName", result.add_countryname);
      formik.setFieldValue("valuation", result.valuation);
      formik.setFieldValue("rentalIncome", result.rentalincome);
      formik.setFieldValue("fees", result.fees);
      formik.setFieldValue("priceperShare", result.pricepershare);
      // formik.setFieldValue("comapnyShare", result.comapnyshare);
      formik.setFieldValue("lockShare", result.lockShare);
      formik.setFieldValue("perOfMaintenance", result?.perOfMaintenance);
      formik.setFieldValue("bidingRangeInPer", result.bidingrangeinper);
      formik.setFieldValue("apartements", result.meta.apartements);
      if (result.images.length !== 0) {
        formik.setFieldValue("images", result.images);
      }
      setPreviewImage(result.images);
      formik.setFieldValue("fullFloor", result.meta.fullFloor);
      formik.setFieldValue("sqareFeet", result.meta.sqareFeet);
      formik.setFieldValue("realestateNumber", result.realestateNumber);
      formik.setFieldValue("tag", result.tag);
      setTagList(result.tag);
      const datalist = result.tag.map((item) => item);
      setTags((prevHashtags) => [...prevHashtags, datalist.join("\n")]);
      // const datalist = result.tag.map((item) => item);
      // setTags((prevItem) => [...prevItem, datalist.join('\n')]);
    } else {
      setIsUpdate(false);
      setTags("");
      // setSingleImage(null)
      setPreviewImage([]);
      formik.resetForm();
    }
  };
  const handleFormSubmit = async (values, action) => {
    const { images } = values;
    if (!values._id) {
      try {
        setLoaderSubmit(true);
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("line1", values.line1);
        formData.append("line2", values.line2);
        formData.append("area", values.area);
        formData.append("countryName", values.countryName);
        formData.append("valuation", values.valuation);
        formData.append("rentalIncome", values.rentalIncome);
        formData.append("priceperShare", values.priceperShare);
        // formData.append("comapnyShare", values.comapnyShare);
        formData.append("lockShare", values.lockShare);
        formData.append("perOfMaintenance", values.perOfMaintenance);
        formData.append("fees", values.fees);
        // formData.append("tag", values.tag);
        tags.forEach((item) => {
          formData.append("tag", item);
        });
        // formData.append("images", values.images);
        formData.append("bidingRangeInPer", values.bidingRangeInPer);
        formData.append("apartements", values.apartements);
        formData.append("fullFloor", values.fullFloor);
        formData.append("sqareFeet", values.sqareFeet);
        formData.append("realestateNumber", values.realestateNumber);
        // if (croppedImageUrl) {
        //     const croppedBlob = await fetch(croppedImageUrl).then((response) =>
        //         response.blob()
        //     );
        //     const croppedFile = new File([croppedBlob], "croppedImage.jpg", {
        //         type: "image/jpeg",
        //     });
        //     formData.append("images", croppedFile);
        // }
        // for (let i = 0; i < images.length; i++) {
        //     if (images !== null && images.length > 0) {
        //         formData.append("images", images[i]);
        //     }
        // }
        if (newImage) {
          for (let i = 0; i < croppedImage.length; i++) {
            if (croppedImage !== null && croppedImage.length > 0) {
              const croppedBlob = await fetch(croppedImage[i]).then(
                (response) => response.blob()
              );
              const croppedFile = new File([croppedBlob], "croppedImage.jpg", {
                type: "image/jpeg",
              });
              formData.append("images", croppedFile);
            }
          }
        }
        const response = await PropertiesService.CreateProperty(formData);
        action.resetForm();
        AllProperties();
        setCroppedImage([]);
        setNewImage(false);
        handleDialogClose();
        setLoaderSubmit(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        setLoaderSubmit(false);
        enqueueSnackbar(e?.response?.data?.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } else {
      try {
        setLoaderSubmit(true);
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("line1", values.line1);
        formData.append("line2", values.line2);
        formData.append("area", values.area);
        formData.append("countryName", values.countryName);
        formData.append("valuation", values.valuation);
        formData.append("rentalIncome", values.rentalIncome);
        formData.append("priceperShare", values.priceperShare);
        // formData.append("comapnyShare", values.comapnyShare);
        formData.append("lockShare", values.lockShare);
        formData.append("perOfMaintenance", values.perOfMaintenance);
        formData.append("fees", values.fees);
        if (isUpdate) {
          if (tagList.length > 0) {
            tagList.forEach((item) => formData.append("tag", item));
          }
        } else {
          if (tags.length > 0) {
            tags.forEach((item) => formData.append("tag", item));
          }
        }
        formData.append("bidingRangeInPer", values.bidingRangeInPer);
        formData.append("apartements", values.apartements);
        formData.append("fullFloor", values.fullFloor);
        formData.append("sqareFeet", values.sqareFeet);
        formData.append("realestateNumber", values.realestateNumber);
        if (newImage) {
          for (let i = 0; i < croppedImage.length; i++) {
            if (croppedImage !== null && croppedImage.length > 0) {
              const croppedBlob = await fetch(croppedImage[i]).then(
                (response) => response.blob()
              );
              const croppedFile = new File([croppedBlob], "croppedImage.jpg", {
                type: "image/jpeg",
              });
              formData.append("images", croppedFile);
            }
          }
        }
        const response = await PropertiesService.UpdateOne(
          values._id,
          formData
        );
        action.resetForm();
        AllProperties();
        setTags("");
        // setSingleImage(null)
        handleDialogClose();
        setCroppedImage([]);
        setNewImage(false);
        setLoaderSubmit(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        setLoaderSubmit(false);
        enqueueSnackbar(e?.response?.data?.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.log("e", e);
      }
    }
  };
  const handleTagAdd = (event) => {
    if (event.key === " " && tagInput.trim() !== "") {
      event.preventDefault();
      const modifiedString = tagInput.replace(/,(\s*)$/, "");
      if (isUpdate) {
        setTagList((prevHashtags) => [...prevHashtags, modifiedString]);
        formik.setFieldValue("tag", modifiedString);
      } else {
        setTags((prevHashtags) => [...prevHashtags, modifiedString]);
        formik.setFieldValue("tag", modifiedString);
      }
      setTagInput("");
    }
  };
  const handleTagRemove = (index) => {
    if (isUpdate) {
      setTagList((prevHashtags) => prevHashtags.filter((_, i) => i !== index));
    } else {
      setTags((prevHashtags) => prevHashtags.filter((_, i) => i !== index));
    }
  };
  useEffect(() => {
    tags.length === 0 && formik.setFieldValue("tag", "");
  }, [tags]);

  useEffect(() => {
    tagList.length === 0 && formik.setFieldValue("tag", "");
  }, [tagList]);

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  const handleImageChange = (e) => {
    setNewImage(true);
    setShowCropper(true);
    setShowImageModal(true);
    handleDialogClose();
    const imgFile = e.currentTarget.files[0];
    console.log("imgFile", imgFile);
    formik.setFieldValue("images", imgFile);
    // setSingleImage(imgFile)
    const imageUrl = URL.createObjectURL(imgFile);
    setSingleImage((previousImages) => [imageUrl]);

    if (imgFile.type.startsWith("image/")) {
      setShowCropper(true);
      setShowImageModal(true);
      handleDialogClose();
      setFileType("image");
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
      };
      reader.readAsDataURL(imgFile);
    }
  };
  async function deleteImageHandler(image) {
    // const dataStatus = singleImage.filter((e) => e !== image);
    const dataStatus = croppedImage.filter((e) => e !== image);
    setCroppedImage(dataStatus);

    const files = await Promise.all(
      dataStatus.map(async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        const fileName = url.split("/").pop();
        return new File([blob], fileName);
      })
    );
    formik.setFieldValue("images", files);
    URL.revokeObjectURL(image);
  }

  const deleteImageHandler2 = async (imageUrl) => {
    await PropertiesService.ImageDelete(encodeURIComponent(id), imageUrl);
    const response = await PropertiesService.GetOne(id);
    const result = response.data.data;
    const latestImage = result.images;
    setPreviewImage(latestImage);
  };
  const handleValuation = async (event, data) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    // formik.setFieldValue("valuation", "KD" + numericValue);
    formik.setFieldValue("valuation", numericValue);
  };
  const handleRentalIncome = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("rentalIncome", numericValue);
  };
  const handlePricePerShare = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("priceperShare", numericValue);
  };
  const handleCompanyShare = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    // formik.setFieldValue("comapnyShare", numericValue);
  };
  const handleLockShare = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("lockShare", numericValue);
  };
  const handleMaintainceShare = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("perOfMaintenance", numericValue);
  };
  const handleFees = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("fees", numericValue);
  };
  const handleBidingRange = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("bidingRangeInPer", numericValue);
  };
  const handleApartement = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("apartements", numericValue);
  };
  const handleFullFloor = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("fullFloor", numericValue);
  };
  const handleSqareFeet = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("sqareFeet", numericValue);
  };
  const handleRealestateNumber = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue("realestateNumber", numericValue);
  };
  const AllProperties = async () => {
    try {
      setIsLoading(true);
      const response = await PropertiesService.GetAllProperties(
        page,
        limit,
        type
      );
      const nonArchiveData = response?.data?.data.filter((item) => {
        if (type) {
          setTotalRows(response?.data?.totalArchiveProperty);
          return item;
        } else {
          setTotalRows(response?.data?.totalUnArchiveProperty);
          return item;
        }
      });
      setPropertyData(nonArchiveData);
      setIsLoading(false);
    } catch (e) {
      console.log("e", e);
    }
  };
  const ProprtyDelete = async () => {
    setDeleteProperty(true);
    const response = await PropertiesService.archiveProperty(deleteId);
    setShowDelete(false);
    // onHide();
    AllProperties();
    setDeleteProperty(false);
    enqueueSnackbar(response.data.message, {
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
    if (propertyData.length === 1) {
      previousPage(page - 1);
    }
  };
  useEffect(() => {
    AllProperties();
    window.scrollTo(0, 0);
  }, [page]);
  const handleDelete = (id, propertyName) => {
    setShowDelete(true);
    setDeleteId(id);
    setPropertyName(propertyName);
  };
  const viewDetails = (id) => {
    navigate(`/properties/details/${id}`, { state: type });
  };
  const viewRecycle = (id) => {
    navigate(`/properties/recycle/`);
  };
  const handleDialogCloseReset = () => {
    handleDialogClose();
    formik.resetForm();
    setTags("");
    // setSingleImage(null)
    setCroppedImage([]);
  };
  const nextPage = (page) => {
    setPage(page);
    // setDisplayedData(getCurrentData(page));
  };
  const previousPage = (page) => {
    setPage(page);
    // setDisplayedData(getCurrentData(page));
  };
  const pageSize = 12;
  const getCurrentData = (currentPage) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return propertyData.slice(startIndex, endIndex);
  };
  const handleInputChange = (event) => {
    if (isUpdate) {
      const inputValue = event.target.value;
      const modifiedString = inputValue
        .split(", ")
        .map((word) => (word.startsWith("#") ? word.substring(1) : word))
        .join(", ");
      setTagInput(modifiedString);
    } else {
      const inputValue = event.target.value;
      const modifiedString = inputValue
        .split(", ")
        .map((word) => (word.startsWith("#") ? word.substring(1) : word))
        .join(", ");
      setTagInput(modifiedString);
    }
  };

  const handleRestoreDialog = (id, propertyName) => {
    setArchiveId(id);
    setArchivePropertyName(propertyName);
    setIsOpen(true);
  };
  const handleRestoreProperty = () => {
    try {
      setRestoreLoader(true);
      const response = PropertiesService.unArchive(archiveId);
      setIsOpen(false);
      AllProperties();
      setRestoreLoader(false);
      enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log("e", e);
    }
  };

  //   const [displayedData, setDisplayedData] = useState(getCurrentData(page));
  return (
    <>
      {showImageModal && (
        <ImageModal
          show={showImageModal}
          onHide={() => setShowImageModal(false)}
          fileType={fileType}
          showCropper={showCropper}
          singleImage={singleImage}
          crop={crop}
          handelDialogOpen={handelDialogOpen}
          zoom={zoom}
          setCrop={setCrop}
          onCropComplete={onCropComplete}
          setZoom={setZoom}
          handleCropDone={handleCropDone}
        />
      )}
      <div className="page-header">
        {type === "false" ? (
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">PROPERTIES</h2>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-start">
            <Button
              style={{ borderRadius: "50%", marginRight: "8px" }}
              onClick={() => navigate("/properties")}
            >
              <i className="d-block fe fe-arrow-left"></i>
            </Button>
            <h2 className="main-content-title tx-24 mg-b-0">
              Deleted Properties
            </h2>
          </div>
        )}
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              variant="primary"
              type="button"
              className="my-2 me-3 btn-icon-text"
              onClick={() => handleDialogOpen("")}
            >
              <i className="fe fe-plus me-2"></i>Add Property
            </Button>
            <Button
              variant="success"
              type="button"
              className="my-2 btn-icon-text"
              onClick={() => viewRecycle()}
            >
              <i className="fa fa-solid fa-recycle me-2"></i>Recycle
            </Button>
            {showDelete && (
              <DeleteModal
                show={showDelete}
                onHide={() => setShowDelete(false)}
                propertyName={propertyName}
                ProprtyDelete={ProprtyDelete}
                deleteProperty={deleteProperty}
                mixdata="property"
              />
            )}
          </div>
        </div>
      </div>
      <div lg={12} className="w-100">
        {show && (
          <PropertiesEditModal
            tags={tags}
            formik={formik}
            handleImageChange={handleImageChange}
            handleValuation={handleValuation}
            handleRentalIncome={handleRentalIncome}
            handlePricePerShare={handlePricePerShare}
            handleCompanyShare={handleCompanyShare}
            handleLockShare={handleLockShare}
            handleMaintainceShare={handleMaintainceShare}
            handleFees={handleFees}
            handleBidingRange={handleBidingRange}
            handleApartement={handleApartement}
            handleFullFloor={handleFullFloor}
            handleSqareFeet={handleSqareFeet}
            handleRealestateNumber={handleRealestateNumber}
            previewImage={previewImage}
            show={show}
            // imageList={imageList}
            // setImageList={setImageList}
            image={image}
            singleImage={singleImage}
            fileType={fileType}
            crop={crop}
            zoom={zoom}
            setCrop={setCrop}
            onCropComplete={onCropComplete}
            setZoom={setZoom}
            handleCropDone={handleCropDone}
            showCropper={showCropper}
            setShowCropper={setShowCropper}
            setSingleImage={setSingleImage}
            handelDialogOpen={handelDialogOpen}
            handleDialogClose={handleDialogCloseReset}
            isUpdate={isUpdate}
            handleTagRemove={handleTagRemove}
            handleInputChange={handleInputChange}
            handleTagAdd={handleTagAdd}
            tagInput={tagInput}
            tagList={tagList}
            deleteImageHandler={deleteImageHandler}
            deleteImageHandler2={deleteImageHandler2}
            croppedImage={croppedImage}
            loaderSubmit={loaderSubmit}
          />
        )}
        <Row className="row row-sm">
          {isLoading ? (
            <img src={Loader1} alt="Loader" height={70} />
          ) : (
            <>
              {propertyData.length === 0 ? (
                <div className="text-center mt-3 mb-3">
                  <p>There are no data available.</p>
                </div>
              ) : (
                <>
                  {propertyData.map((data, index) => {
                    const address =
                      data.name +
                      ", " +
                      data.add_area +
                      ", " +
                      data.add_countryname;
                    const lines = data.add_line1 + " " + data.add_line2;
                    return (
                      <>
                        <Col
                          sm={6}
                          md={6}
                          xl={4}
                          xs={12}
                          style={{ paddingBottom: "20px" }}
                          key={index}
                        >
                          <div>
                            <Card className="custom-card p-3 mb-0">
                              <div
                                onClick={() => viewDetails(data.id)}
                                className="align-items-center"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={data.images[0]}
                                    style={{
                                      width: "500px",
                                      height: "250px",
                                      // height: "200px",
                                      // width: "500px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                    alt="propertyimage"
                                  />
                                </div>
                                <div style={{ width: "100%" }}>
                                  <div className="d-flex flex-column">
                                    <div className="justify-content-start">
                                      <label
                                        className="tx-18 fw-bold mt-2 mb-0 property-text"
                                        title={address}
                                      >
                                        {address}
                                      </label>
                                      <Typography
                                        className="text-muted property-text"
                                        title={lines}
                                      >
                                        {lines}{" "}
                                      </Typography>
                                      <Typography
                                        className="text-muted property-text"
                                        title={data?.realestatenumber}
                                      >
                                        <span>RealEstate Number: </span>
                                        <span
                                          style={{ fontWeight: "bold" }}
                                          className="text-muted"
                                        >
                                          {data?.realestatenumber}
                                        </span>
                                      </Typography>
                                      <Typography
                                        className="text-muted property-text"
                                        title={data?.realestatenumber}
                                      >
                                        <span>Created At: </span>
                                        <span
                                          style={{ fontWeight: "bold" }}
                                          className="text-muted"
                                        >
                                          {new Date(data?.createdat)
                                            .toLocaleDateString("en-GB", {
                                              day: "2-digit",
                                              month: "short",
                                              year: "numeric",
                                            })
                                            .replace(/ /g, "-")}
                                        </span>
                                      </Typography>
                                      <div className="d-flex flex-wrap my-2 property-text">
                                        {data?.tag.map(
                                          (tag, index) =>
                                            tag && (
                                              <Badge
                                                bg="none"
                                                key={index}
                                                pill
                                                style={{
                                                  fontSize: "17px",
                                                  marginRight: "5px",
                                                  marginBottom: "5px",
                                                  wordBreak: "break-all",
                                                  backgroundColor: "#e0efe9",
                                                  color: "#023220",
                                                }}
                                              >
                                                {tag}
                                              </Badge>
                                            )
                                        )}
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <Typography
                                          style={{ marginRight: "8px" }}
                                        >
                                          <span
                                            style={{ fontWeight: "bold" }}
                                            className="text-muted"
                                          >
                                            KD
                                          </span>
                                        </Typography>
                                        <Typography className="tx-18 fw-bold">
                                          {data.valuation}
                                        </Typography>
                                      </div>
                                      {/* <div style={{ background: "#F6F7F9", borderRadius: '6px', padding: '8px', gap: '8px' }}> */}
                                      <div className="d-flex align-items-center justify-content-between">
                                        <Typography className="text-muted">
                                          Annulised return
                                        </Typography>
                                        <Typography className="tx-14 fw-bold">
                                          {/* {`${data.annualReturn}%`} */}
                                          {data?.annualReturn
                                            ? `${data?.annualReturn?.toFixed(
                                                2
                                              )}%`
                                            : `${0}%`}
                                        </Typography>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <Typography className="text-muted">
                                          Price per share
                                        </Typography>
                                        <Typography className="tx-14 fw-bold">{`${data?.pricepershare?.toFixed(
                                          2
                                        )} KD`}</Typography>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <Typography className="text-muted">
                                          Rental income per share
                                        </Typography>
                                        <Typography className="tx-14 fw-bold">
                                          {data?.rentalIncomePerShare
                                            ? `${data?.rentalIncomePerShare?.toFixed(
                                                2
                                              )} KD`
                                            : `${0}%`}
                                        </Typography>
                                      </div>
                                      {/* </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {type === "false" ? (
                                <div className="d-flex align-items-end my-2">
                                  <button
                                    className="ms-auto btn btn-primary btn-sm me-1"
                                    onClick={() => handleDialogOpen(data.id)}
                                  >
                                    <i className="fe fe-edit"></i>
                                  </button>
                                  <div className="me-2 align-items-baseline">
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() => {
                                        handleDelete(data.id, data.name);
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex align-items-end my-2">
                                  <button
                                    className="ms-auto btn btn-primary btn-sm me-1"
                                    onClick={() =>
                                      handleRestoreDialog(data.id, data.name)
                                    }
                                  >
                                    <i className="fe fe-refresh-ccw"></i>
                                  </button>
                                  {isOpen && (
                                    <RestoreModal
                                      show={isOpen}
                                      onHide={() => setIsOpen(false)}
                                      archivePropertyName={archivePropertyName}
                                      handleRestoreProperty={
                                        handleRestoreProperty
                                      }
                                      AllProperties={AllProperties}
                                      restoreLoader={restoreLoader}
                                    />
                                  )}
                                </div>
                              )}
                            </Card>
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </>
              )}
              {totalRows > 6 && (
                <Pagination
                  className="pagination-circle mb-0 ms-lg-3"
                  style={{ justifyContent: "center" }}
                >
                  <Pagination.Item
                    onClick={() => previousPage(page - 1)}
                    disabled={page === 1}
                  >
                    <i className="fas fa-angle-double-left"></i>
                  </Pagination.Item>
                  {Array.from({ length: Math.ceil(totalRows / limit) }).map(
                    (_, index) => {
                      const pageNumber = index + 1;
                      return (
                        <Pagination.Item
                          onClick={() => nextPage(pageNumber)}
                          key={pageNumber}
                          active={pageNumber === page}
                        >
                          {pageNumber}
                        </Pagination.Item>
                      );
                    }
                  )}
                  <Pagination.Item
                    onClick={() => nextPage(page + 1)}
                    disabled={page === Math.ceil(totalRows / limit)}
                  >
                    <i className="fas fa-angle-double-right"></i>
                  </Pagination.Item>
                </Pagination>
              )}
            </>
          )}
        </Row>
      </div>
    </>
  );
};

export default MergeProperties;
