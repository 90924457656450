import React from 'react'
import MergeProperties from './MergeProperties';
import withLoader from '../../../layout/loader/withLoader';
const RecycleProperty = () => {
    return (
        <>
            <MergeProperties type="true" />
        </>
    )
}
export default withLoader(RecycleProperty)
