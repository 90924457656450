import React from "react";
import { Button, Modal } from "react-bootstrap";

const StatusChangeModal = ({
  statusChange,
  currentUser,
  onConfirm,

  ...props
}) => {
  const handleConfirm = (e) => {
    e.preventDefault();
    onConfirm();
  };
  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{`${statusChange.label} ${currentUser?.firstName} ${currentUser?.lastName}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <i
          className={`icon ion-ios-checkmark-circle-outline tx-100 ${
            statusChange.variant === "success" ? "tx-success" : "tx-danger"
          } mg-t-20`}
        ></i>

        <h4
          className={`${
            statusChange.variant === "success" ? "tx-success" : "tx-danger"
          } tx-semibold mg-b-20`}
        >
          {statusChange.label}?
        </h4>
        <p className="mg-b-20 mg-x-20">
          Are you sure you want to {statusChange.label}{" "}
          <b>
            {currentUser?.firstName} {currentUser?.lastName}
          </b>
          ?
        </p>

        <Button
          type="button"
          onClick={handleConfirm}
          variant={statusChange.variant}
        >
          {statusChange.label}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default StatusChangeModal;
