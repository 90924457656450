import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import userDefault from "./../../assets/img/svgs/userDefault.svg";
import Loader1 from "../../assets/img/svgs/loader.svg";
import UserService from "../services/user.service";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const TransferShareModal = ({
  shareModal,
  setShareModal,
  shareDetails,
  userData,
  portfolioData,
}) => {
  const email = localStorage.getItem("email");
  const [options, setOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isToDropdownOpen, setToDropdownOpen] = useState(false);

  // Toggle dropdown visibility
  const handleToggle = () => {
    setDropdownOpen((prevState) => !prevState);
    if (isToDropdownOpen) setToDropdownOpen(false);
  };

  // Handle email change
  const handleEmailChange = (e) => {
    formik.setFieldValue("fromEmail", e.target.value);
    setDropdownOpen(false); // Close the dropdown after selection
  };

  // Handle option click (custom dropdown)
  const handleOptionSelect = (value) => {
    formik.setFieldValue("fromEmail", value); // Set selected value
    setDropdownOpen(false); // Close the dropdown
  };

  // Handle blur (validation) event
  const handleBlur = (e) => {
    formik.handleBlur(e);
    setDropdownOpen(false); // Close dropdown on blur
  };


  // Toggle dropdown visibility
  const handleToToggle = () => {
    setToDropdownOpen((prevState) => !prevState);
    if (isDropdownOpen) setDropdownOpen(false);
  };

  // Handle email change
  const handleToEmailChange = (e) => {
    formik.setFieldValue("toEmail", e.target.value);
    setToDropdownOpen(false); // Close the dropdown after selection
  };

  // Handle option click (custom dropdown)
  const handleToOptionSelect = (value) => {
    formik.setFieldValue("toEmail", value); // Set selected value
    setToDropdownOpen(false); // Close the dropdown
  };

  // Handle blur (validation) event
  const handleToBlur = (e) => {
    formik.handleBlur(e);
    setToDropdownOpen(false); // Close dropdown on blur
  };

  const initialValues = {
    fromEmail: "",
    propertyId: "",
    numberOfShare: "",
    toEmail: "",
    isFees: false,
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    fromEmail: Yup.string()
      .email("Invalid email address")
      .required("From email is required"),
    // propertyId: Yup.string().required("Property ID is required"),
    numberOfShare: Yup.number()
      .required("Number of shares is required")
      .positive("Number must be positive"),
    toEmail: Yup.string()
      .email("Invalid email address")
      .required("To email is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const payload = { ...values, propertyId: shareDetails?.propertyId };
    try {
      if (values?.fromEmail === values?.toEmail) {
        throw new Error("You cannot transfer shares to yourself.");
      }
      const response = await UserService?.sellShare(payload);
      if (response) {
        enqueueSnackbar(
          response?.data?.data?.messsage || "Share transfer succesfully!",
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
        portfolioData();
        setSubmitting(false);
      }
    } catch (error) {
      setSubmitting(false);
      enqueueSnackbar(error?.response?.data?.message || error.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      setShareModal(false);
    }
  };

  // Formik setup with validation and form submission
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    formik.resetForm();
    setShareModal(false);
  };

  useEffect(() => {
    setOptions([
      { value: email, label: email },
      { value: userData?.email, label: userData?.email },
    ]);
  }, [email, userData]);

  useEffect(() => {
    setToOptions([
      { value: email, label: email },
      { value: userData?.email, label: userData?.email },
    ]);
  }, [email, userData]);

  return (
    <>
      <Modal
        size="large"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={shareModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="text-center"
          style={{ paddingBottom: "initial" }}
        >
          <div className="col-122">
            <div className="card custom-card">
              <div
                className="col-12"
                style={{
                  overflow: "auto",
                  paddingTop: "15px",
                  marginBottom: "15px",
                  maxHeight: "650px",
                  padding: "0px 0px 0px 10px",
                }}
              >
                <div className="mb-3">
                  <img
                    src={userData?.profile || userDefault}
                    alt="profile"
                    width={100}
                    height={100}
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                  />
                  <div
                    className="font-weight-bold"
                    style={{ fontSize: "large" }}
                  >
                    {userData?.username}
                  </div>
                </div>
                <Form onSubmit={formik.handleSubmit}>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        From
                      </Form.Label>
                    </Col>
                    <Col xs={8} className="position-relative">
                      <div className="d-flex align-items-center position-relative">
                        {/* Regular input field for typing */}
                        <Form.Control
                          type="text"
                          name="fromEmail"
                          value={formik.values.fromEmail}
                          onChange={handleEmailChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          placeholder="Type your email"
                          isInvalid={
                            formik.submitCount > 0 && formik.errors.fromEmail
                          }
                        />

                        {/* Dropdown icon to open options */}
                        <button
                          type="button"
                          onClick={handleToggle}
                          style={{
                            position: "absolute",
                            right: "1px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            border: "0",
                            background: "white",
                            height: "34px",
                            width: "34px",
                          }}
                        >
                          {isDropdownOpen ? (
                            <FiChevronUp size={18} /> // Show "up" icon when open
                          ) : (
                            <FiChevronDown size={18} /> // Show "down" icon when closed
                          )}
                        </button>
                      </div>

                      {/* Custom Dropdown options */}
                      <div
                        style={{
                          padding: "0 15px",
                          position: "absolute",
                          top: "100%",
                          left: "0",
                          width: "100%",
                          zIndex: 1000,
                        }}
                      >
                        {isDropdownOpen && (
                          <div
                            style={{
                              border: "1px solid #ced4da",
                              borderRadius: "0.375rem",
                              backgroundColor: "#fff",
                              marginTop: "5px",
                              maxHeight: "150px",
                              overflowY: "auto",
                            }}
                          >
                            {options.map((option, index) => (
                              <div
                                key={index}
                                onClick={() => handleOptionSelect(option.value)}
                                style={{
                                  padding: "8px 12px",
                                  cursor: "pointer",
                                  borderBottom: "1px solid #f1f1f1",
                                  backgroundColor:
                                    formik.values.fromEmail === option.value
                                      ? "#f1f1f1"
                                      : "#fff",
                                }}
                              >
                                {option.label}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>

                      {/* Show validation errors */}
                      {formik.submitCount > 0 && formik.errors.fromEmail ? (
                        <div className="text-danger text-start">
                          {formik.errors.fromEmail}
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{
                          color: "black",
                          marginBottom: "0px",
                        }}
                      >
                        Property ID
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <Form.Control
                        type="text"
                        aria-label="Selected property"
                        name="propertyId"
                        value={`${shareDetails?.realestateNumber}(${shareDetails?.propertyName})`}
                        readOnly
                        isInvalid={
                          formik.submitCount > 0 && formik.errors.propertyId
                        }
                      />

                      {formik.submitCount > 0 && formik.errors.propertyId ? (
                        <div className="text-danger text-start">
                          {formik.errors.propertyId}
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        Number of shares
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <Form.Control
                        type="number"
                        name="numberOfShare"
                        value={formik.values.numberOfShare}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.submitCount > 0 &&
                          formik.errors.numberOfShare
                        }
                      />
                      {formik.submitCount > 0 &&
                      formik.errors.numberOfShare ? (
                        <div className="text-danger text-start">
                          {formik.errors.numberOfShare}
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        To
                      </Form.Label>
                    </Col>
                    <Col xs={8} className="position-relative">
                      <div className="d-flex align-items-center position-relative">
                        {/* Regular input field for typing */}
                        <Form.Control
                          type="text"
                          name="toEmail"
                          value={formik.values.toEmail}
                          onChange={handleToEmailChange}
                          onBlur={handleToBlur}
                          autoComplete="off"
                          placeholder="Type your email"
                          isInvalid={
                            formik.submitCount > 0 && formik.errors.toEmail
                          }
                        />

                        {/* Dropdown icon to open options */}
                        <button
                          type="button"
                          onClick={handleToToggle}
                          style={{
                            position: "absolute",
                            right: "1px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            border: "0",
                            background: "white",
                            height: "34px",
                            width: "34px",
                          }}
                        >
                          {isToDropdownOpen ? (
                            <FiChevronUp size={18} /> // Show "up" icon when open
                          ) : (
                            <FiChevronDown size={18} /> // Show "down" icon when closed
                          )}
                        </button>
                      </div>

                      {/* Custom Dropdown options */}
                      <div
                        style={{
                          // padding: "0 15px",
                          position: "absolute",
                          bottom: "46px",
                          left: "15px",
                          width: "100%",
                          maxWidth: "282px",
                          zIndex: 1000,
                        }}
                      >
                        {isToDropdownOpen && (
                          <div
                            style={{
                              border: "1px solid #ced4da",
                              borderRadius: "0.375rem",
                              backgroundColor: "#fff",
                              marginTop: "5px",
                              maxHeight: "150px",
                              overflowY: "auto",
                            }}
                          >
                            {options.map((option, index) => (
                              <div
                                key={index}
                                onClick={() =>
                                  handleToOptionSelect(option.value)
                                }
                                style={{
                                  padding: "8px 12px",
                                  cursor: "pointer",
                                  borderBottom: "1px solid #f1f1f1",
                                  backgroundColor:
                                    formik.values.toEmail === option.value
                                      ? "#f1f1f1"
                                      : "#fff",
                                }}
                              >
                                {option.label}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>

                      {/* Show validation errors */}
                      {formik.submitCount > 0 && formik.errors.toEmail ? (
                        <div className="text-danger text-start">
                          {formik.errors.toEmail}
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      ></Form.Label>
                    </Col>
                    <Col xs={8} className="d-flex">
                      <Form.Check
                        type="checkbox"
                        label="With fees"
                        name="isFees"
                        checked={formik.values.isFees}
                        onChange={formik.handleChange}
                      />
                    </Col>
                  </Row>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={formik.isSubmitting}
                      style={{
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "15px",
                        textAlign: "center",
                      }}
                    >
                      {formik.isSubmitting ? <Spinner /> : "Submit"}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransferShareModal;
