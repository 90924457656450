import React, { useCallback, useEffect, useRef } from "react";
import withLoader from "../../../layout/loader/withLoader";
import DataTable from "react-data-table-component";
import { Button, Card } from "react-bootstrap";
import { useState } from "react";
import Loader1 from "../../../../assets/img/svgs/loader.svg";
import img from "../../../../assets/img/svgs/img.svg";
import plus from "../../../../assets/img/svgs/plus.svg";
import SupportService from "../../../services/support.service";
import { Link, useNavigate } from "react-router-dom";
import ModelSS from "./ModelSS";
import { useDebounce } from "use-debounce";

const Support = () => {
  const [searchText, setSearchText] = useState("");
  const [value] = useDebounce(searchText, 400);
  const [totalRows, setTotalRows] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [ticketData, setTicketData] = useState("");
  const [show, setshow] = useState(false);
  const [screenshot, setScreenshots] = useState([]);
  const navigate = useNavigate();
  const handelDialogOpen = () => {
    setshow(true);
  };

  const AllTickets = async () => {
    try {
      setIsLoading(true);
      const response = await SupportService.GetAllTickets(
        pageNumber,
        dataPerPage,
        value,
      );
      setTotalRows(response?.data?.totalTicket);
      const result = response.data.data;
      setTicketData(result);
      setIsLoading(false);
    } catch (e) {
      console.log("e", e);
    }
  };
  const handleScreenshotModal = async (row) => {
    handelDialogOpen();
    const images = row?.screenshot || [];
    setScreenshots(images);
  };
  const handlePageChange = async (newPageNumber) => {
    setIsLoading(true);
    setPageNumber(newPageNumber);
    setIsLoading(false);
  };
  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    setIsLoading(true);
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
    setIsLoading(false);
  };
  useEffect(() => {
    AllTickets();
  }, [pageNumber, dataPerPage, value]);

  const columns = [
    {
      name: <b>USER NAME</b>,
      cell: (row) => (row.username ? row.username : "-"),
      sortable: true,
    },
    {
      name: <b>TICKET ID</b>,
      cell: (row) => (row.id ? row.id : "-"),
      sortable: true,
      width: "285px",
    },
    {
      name: <b>CATEGORY</b>,
      selector: (row) => (row.sc_name ? row.sc_name : "-"),
      sortable: true,
    },
    {
      name: <b>SUBJECT</b>,
      cell: (row) => (row.subject ? row.subject : "-"),
      sortable: true,
    },
    {
      name: <b>DESCRIPTION</b>,
      cell: (row) => (row.description ? row.description : "-"),
      sortable: true,
    },
    {
      name: <b>SCREENSHOT</b>,
      selector: (row) => (
        <>
          {row?.screenshot && row.screenshot.length > 0 ? (
            <div
              className="demo-avatar-group my-auto float-end"
              onClick={() => handleScreenshotModal(row)}
            >
              {
                row?.screenshot &&
                  row.screenshot.length > 0 &&
                  row?.screenshot.slice(0, 4).map((item, index) => (
                    <div className="main-img-user avatar-sm" key={index}>
                      <img alt="avatar" className="rounded-circle" src={item} />
                    </div>
                  ))
                // : (
                //     <div className="main-img-user avatar-sm">
                //         <img
                //             alt="avatar"
                //             className="rounded-circle"
                //             src={img}
                //         />
                //     </div>
                // )
              }
              {row?.screenshot && row.screenshot.length > 4 && (
                <div className="main-img-user avatar-sm">
                  <img alt="avatar" className="rounded-circle" src={plus} />
                </div>
              )}
            </div>
          ) : (
            <p>N/A</p>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: <b>STATUS</b>,
      selector: (row) => (
        <button
          className={`btn btn-sm action-btn btn-${
            row.status === "active" || row.status === "Resolved"
              ? "success"
              : "warning"
          } text-white`}
          style={{
            width: "125px",
            textAlign: "center",
            cursor: "default",
            background: row.status === "InProgress" ? "#7373c9" : undefined,
          }}
        >
          <i
            style={{ paddingRight: "3px" }}
            class={`fa fa-${
              row.status === "active" || row.status === "Resolved"
                ? "check"
                : row.status === "InProgress"
                ? "hourglass-half"
                : "clock"
            }`}
          ></i>{" "}
          {row.status === ("active" || "Resolved") ? "Resolved" : row.status}
        </button>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: <b>ACTION</b>,
      selector: (row) => (
        <Link
          to={`/support/details/${row.userid}`}
          state={{ data: row }}
          // onClick={() => viewDetails(row.userid, row)}
          style={{ height: "28px" }}
          className="d-flex align-items-center btn btn-primary btn-sm"
        >
          <div className="d-flex">
            <i className="fa fa-eye"></i>
          </div>
        </Link>
      ),
    },
  ];
  return (
    <>
      {show && (
        <ModelSS
          show={show}
          onHide={() => setshow(false)}
          screenshot={screenshot}
        />
      )}
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">SUPPORT</h2>
        </div>
        <div className="d-flex align-items-center">
          {/* {show && <AddUser show={show} handleDialogClose={handleDialogCloseReset} handleSelectRoleChange={handleSelectRoleChange} BrandsOptions={BrandsOptions} userRole={userRole} formik={formik} id={id} />}
                        {<DeleteUserModal deleteModal={deleteModal} onHide={handleDeleteNodal} deleteId={deleteId} setDeleteModal={setDeleteModal} emailUse={emailUse} />} */}
        </div>
      </div>
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              style={{
                border: "1px solid #ced4da",
                borderRadius: "4px",
                padding: "0.375rem 0.75rem",
                marginBottom: "10px",
              }}
            />
            <div className="responsive">
              <Card className="custom-card overflow-hidden p-1">
                <div>
                  <DataTable
                    data={ticketData}
                    columns={columns}
                    noHeader
                    persistTableHead
                    center={true}
                    highlightOnHover
                    striped
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={dataPerPage}
                    onChangeRowsPerPage={(page, totalRows) => {
                      handlePerPageChange(page, 1);
                    }}
                    onChangePage={handlePageChange}
                    progressPending={isLoading}
                    progressComponent={
                      <img src={Loader1} alt="Loader" height={70} />
                    }
                  />
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default withLoader(Support);
