import serverCall from "../../serverCall";
const sendOtp = async (email) => {
  const response = serverCall.post(`users/admin/send-otp?email=${email}`);
  return response;
};
const otpVarify = async (email, otp) => {
  const response = serverCall.post(
    `users/admin/otp-verification?email=${email}&otp=${otp}`
  );
  return response;
};
const changePassword = async (data) => {
  const response = serverCall.post(`users/admin/change-password`, data);
  return response;
};
const ProfileService = { sendOtp, otpVarify, changePassword };
export default ProfileService;
