import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const FreezeModal = (props) => {
    const { freezzeUser, freezeChange } = props
    const handleFreeze = (e) => {
        e.preventDefault();
        freezzeUser()
    }
    return (
        <>
            <Modal
                {...props}
                size="large"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton >
                    <Modal.Title>{`${freezeChange ? 'Unfreeze' : 'Freeze'} ${props.freezeEmail}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <i className={`icon ion-ios-checkmark-circle-outline tx-100 ${freezeChange ? 'tx-success' : 'tx-danger'}  mg-t-20`}></i>

                    <h4 className={`${freezeChange ? 'tx-success' : 'tx-danger'} tx-semibold mg-b-20`}>{freezeChange ? 'Unfreeze?' : 'Freeze?'}</h4>
                    <p className="mg-b-20 mg-x-20 ">
                        Are you sure want to {freezeChange ? 'Unfreeze' : 'Freeze'} <b>
                            {props.freezeEmail}
                        </b>?
                    </p>

                    <Button
                        type="button"
                        onClick={(e) => { handleFreeze(e) }}
                        variant={freezeChange ? 'success' : 'danger'}
                    >
                        {freezeChange ? 'Unfreeze' : 'Freeze'}
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FreezeModal
