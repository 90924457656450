import React from 'react'
import { Modal } from 'react-bootstrap';
import Cropper from 'react-easy-crop';

const ImageModal = (props) => {
    return (
        <Modal
            {...props}
            // className='screenshort-modals'
            size="large"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton onClick={() => {
                props?.handelDialogOpen();
                props?.onHide();
            }} >
                <Modal.Title>Crop Image
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4 text-center" style={{ height: "600px" }} >
                {props?.fileType === "image" && (
                    <>
                        {props?.showCropper && (
                            <div>
                                <Cropper
                                    image={props?.singleImage}
                                    crop={props?.crop}
                                    zoom={props?.zoom}
                                    aspect={16 / 9}
                                    onCropChange={props?.setCrop}
                                    onCropComplete={props?.onCropComplete}
                                    onZoomChange={props?.setZoom}
                                />
                                <button
                                    style={{
                                        zIndex: "10000",
                                        position: "absolute",
                                        right: '10px',
                                        bottom: '10px'
                                    }}
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={props?.handleCropDone}
                                >
                                    Done
                                </button>
                            </div>
                        )}
                    </>
                )}
            </Modal.Body >

        </Modal >
    );
}

export default ImageModal
