import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import useDialogState from "../../../hooks/useDialogState";
import AddModal from "./AddModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import DataTable from "react-data-table-component";
import Loader1 from "../../../../assets/img/svgs/loader.svg";

const UserManagement = () => {
  const [userData, setUsersData] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [dataPerPage, setDataPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const {
    open: show,
    handleClickOpen: handelDialogOpen,
    handleClickClose: handleDialogClose,
  } = useDialogState();

  const initialValue = {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter the first name!"),
    lastName: Yup.string().required("Please enter the last name!"),
    email: Yup.string().required("Please enter the email!"),
    role: Yup.string().required("Please enter the role!"),
  });
  const optionsRole = [
    { label: "Moderator", value: "moderator" },
    { label: "Admin", value: "admin" },
  ];
  const handleFormSubmit = () => {
    handleDialogClose();
  };
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });
  const handleDialogOpen = async (id) => {
    handelDialogOpen();
    if (id) {
    } else {
    }
  };
  const handlePageChange = async (newPageNumber) => {
    setPageNumber(newPageNumber);
  };
  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
  };
  const columns = [
    {
      name: <b>FIRST NAME</b>,
      // cell: (row) => (row.firstName ? row.firstName : "-"),
      sortable: true,
    },
    {
      name: <b>LAST NAME</b>,
      // cell: (row) => (row.lastName ? row.lastName : "-"),
      sortable: true,
    },
    {
      name: <b>EMAIL</b>,
      // selector: (row) => (row.email ? row.email : "-"),
      sortable: true,
    },
    {
      name: <b>Role</b>,
      // selector: (row) => (row.email ? row.email : "-"),
      sortable: true,
    },
    {
      name: <b>Action</b>,
      // selector: (row) => (row.email ? row.email : "-"),
      selector: (row) => (
        <div className="d-flex">
          <div
            // onClick={() => handleDetailsModal(row.id)}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-pencil"></i>
          </div>
          {/* <Link
                // to={`/ users / details / ${ row.id }`}
                onClick={() => handleFreezeModal(row)}
                className={`btn ${ row.isFreeze === true ? 'btn-danger' : 'btn-primary' } btn - sm`}
            >
                <i className="mdi mdi-account-off"></i>
            </Link> */}
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">USER MANAGEMENT</h2>
        </div>

        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              variant="primary"
              type="button"
              className="my-2 me-3 btn-icon-text"
              onClick={() => handleDialogOpen("")}
            >
              <i className="fe fe-plus me-2"></i>Add
            </Button>
          </div>
        </div>
      </div>
      <div lg={12} className="w-100">
        {show && (
          <AddModal
            show={show}
            onHide={handleDialogClose}
            formik={formik}
            optionsRole={optionsRole}
          />
        )}

        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden">
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "wrap",
                      gap: "10px",
                      marginBottom: "14px",
                      marginTop: "4px",
                    }}
                  ></div>
                  <DataTable
                    data={userData}
                    columns={columns}
                    noHeader
                    persistTableHead
                    center={true}
                    highlightOnHover
                    striped
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={dataPerPage}
                    onChangeRowsPerPage={(page, totalRows) => {
                      handlePerPageChange(page, 1);
                    }}
                    onChangePage={handlePageChange}
                    progressPending={isLoading}
                    progressComponent={
                      <img src={Loader1} alt="Loader" height={70} />
                    }
                  />
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default UserManagement;
