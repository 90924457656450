import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import Select from "react-select";

const AddModal = (props) => {
  const { isUpdate, formik, loaderSubmit, optionsRole } = props;
  return (
    <>
      <Modal
        {...props}
        onHide
        size="large"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{isUpdate ? "Update" : "Add"}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="text-center"
          style={{ paddingBottom: "initial" }}
        >
          <div className="col-122">
            <div className="card custom-card">
              <form onSubmit={formik.handleSubmit}>
                <div
                  className="col-12"
                  style={{
                    overflow: "auto",
                    paddingTop: "15px",
                    marginBottom: "15px",
                    maxHeight: "650px",
                    padding: "10px 10px 0px 10px",
                  }}
                >
                  <Form.Group
                    controlId="validationFormik101"
                    className="position-relative"
                  >
                    <Form.Label
                      style={{
                        textAlign: "start",
                        color: "#000",
                        marginTop: "15px",
                      }}
                    >
                      First name<span className="tx-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.firstName && formik.touched.firstName ? (
                      <p className="text-start error">
                        {formik.errors.firstName}
                      </p>
                    ) : null}
                  </Form.Group>
                  <Form.Group
                    controlId="validationFormik101"
                    className="position-relative"
                  >
                    <Form.Label
                      style={{
                        textAlign: "start",
                        color: "#000",
                        marginTop: "15px",
                      }}
                    >
                      Last name<span className="tx-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.lastName && formik.touched.lastName ? (
                      <p className="text-start error">
                        {formik.errors.lastName}
                      </p>
                    ) : null}
                  </Form.Group>
                  <Form.Group
                    controlId="validationFormik101"
                    className="position-relative"
                  >
                    <Form.Label
                      style={{
                        textAlign: "start",
                        color: "#000",
                        marginTop: "15px",
                      }}
                    >
                      Email<span className="tx-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.email && formik.touched.email ? (
                      <p className="text-start error">{formik.errors.email}</p>
                    ) : null}
                  </Form.Group>
                  <Form.Group
                    controlId="validationFormik101"
                    className="position-relative"
                  >
                    <Form.Label
                      style={{
                        textAlign: "start",
                        color: "#000",
                        marginTop: "15px",
                      }}
                    >
                      Role<span className="tx-danger">*</span>
                    </Form.Label>
                    <Select
                      value={optionsRole.find(
                        (res) => res.value === formik.values.role
                      )}
                      options={optionsRole}
                      placeholder="Choose one"
                      onChange={(selected) => {
                        formik.setFieldValue("role", selected.value);
                      }}
                    />
                    {formik.errors.role && formik.touched.role ? (
                      <p className="text-start error">{formik.errors.role}</p>
                    ) : null}
                  </Form.Group>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    type="submit"
                    style={{
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "15px",
                      textAlign: "center",
                    }}
                  >
                    {loaderSubmit ? (
                      <Spinner />
                    ) : isUpdate ? (
                      "Update"
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddModal;
