import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserService from "../services/user.service";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const RentalIncome = ({
  incomeModal,
  handleIncomeClose,
  shareIncomeDetails,
  userData,
  setIncomeModal,
  portfolioData,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [values, setValue] = useState("");

  const initialValues = {};
  const handleSubmit = async (values) => {
    setValue(values);
    setShowConfirmModal(true);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  const handleConfirmSubmit = async () => {
    setShowConfirmModal(false);
    setIncomeModal(false);
    const payload = {
      userId: userData?.id,
      propertyId: shareIncomeDetails?.propertyId,
      amount: shareIncomeDetails?.totalRentalIncome,
      rentalIncomeDetail: shareIncomeDetails?.rentalIncomeDetails,
    };
    try {
      const response = await UserService?.rentalIncome(payload);
      if (response) {
        enqueueSnackbar(
          response?.data?.data?.message || "Rental income transfer successful!",
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
        portfolioData();
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    formik.setSubmitting(false);
  };

  const handleConfirmCancel = () => {
    setShowConfirmModal(false);
  };

  return (
    <>
      <Modal
        size="large"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={incomeModal && !showConfirmModal}
        onHide={handleIncomeClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transfer Rental Income</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-122">
            <div className="card custom-card">
              <div
                className="col-12"
                style={{
                  overflow: "auto",
                  paddingTop: "15px",
                  // marginBottom: "15px",
                  maxHeight: "650px",
                  padding: "0px 0px 0px 10px",
                }}
              >
                <Form onSubmit={formik.handleSubmit}>
                  <Row className="mb-2">
                    <Col xs={8} className="d-flex align-items-center">
                      Last month total rental income is
                      <strong style={{ marginLeft: "5px" }}>
                        {shareIncomeDetails?.totalRentalIncome}
                      </strong>
                    </Col>
                  </Row>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={formik.isSubmitting}
                      style={{
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "15px",
                        textAlign: "center",
                      }}
                    >
                      {formik.isSubmitting ? <Spinner /> : "Transfer"}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={showConfirmModal}
        onHide={handleConfirmCancel}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span style={{ marginLeft: "10px" }}>
            Are you sure to transfer this amount?
          </span>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmSubmit}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RentalIncome;
