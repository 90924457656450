import React from 'react'
import withLoader from '../../../layout/loader/withLoader'
import MergeProperties from './MergeProperties';

const Properties = () => {
    return (
        <>
            <MergeProperties type="false" />
        </>
    )
}

export default withLoader(Properties)
