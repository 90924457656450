import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

const MainDashboard = ({ valuation, tab }) => {
  
  // One year grouping: three-month intervals
  const getThreeMonthGroupingData = (data) => {
    if (!data || data.length === 0) return [];
    const groupedValues = [];

    data.forEach((entry) => {
      groupedValues.push(entry.value);  // Use the `value` directly for each 3-month period
    });

    return groupedValues;
  };

  // Five year grouping: one-year intervals
  const getYearlyGroupingData = (data) => {
    if (!data || data.length === 0) return [];

    const groupedValues = [];

    data.forEach((entry) => {
      groupedValues.push(entry.value);  // Use the `value` directly for each 1-year period
    });

    return groupedValues;
  };

  // Generate three-month labels (e.g., "Nov 2023-Jan 2024")
  const generateThreeMonthLabels = (data) => {
    if (!data || data.length === 0) return [];
    const labels = [];

    data.forEach((entry) => {
      const startDate = new Date(entry.startDate);
      const endDate = new Date(entry.endDate);

      const startMonth = startDate.toLocaleString('default', { month: 'short' });
      const endMonth = endDate.toLocaleString('default', { month: 'short' });
      const startYear = startDate.getFullYear();

      labels.push(`${startMonth} ${startYear}-${endMonth} ${endDate.getFullYear()}`);
    });

    return labels;
  };

  // Generate one-year labels (e.g., "Oct 2021 - Oct 2022")
  const generateYearlyLabels = (data) => {
    if (!data || data.length === 0) return [];
    const labels = [];

    data.forEach((entry) => {
      const startDate = new Date(entry.startDate);
      const endDate = new Date(entry.endDate);

      const startMonth = startDate.toLocaleString('default', { month: 'short' });
      const endMonth = endDate.toLocaleString('default', { month: 'short' });
      const startYear = startDate.getFullYear();

      labels.push(`${startMonth} ${startYear} - ${endMonth} ${endDate.getFullYear()}`);
    });

    return labels;
  };

  // One-year data: Three-month grouping
  const oneYearData = getThreeMonthGroupingData(valuation);
  const oneYearLabels = generateThreeMonthLabels(valuation);

  // Five-year data: One-year grouping
  const fiveYearData = getYearlyGroupingData(valuation);
  const fiveYearLabels = generateYearlyLabels(valuation);

  const chartData = {
    labels: tab === "oneYear" ? oneYearLabels : fiveYearLabels,
    datasets: [
      {
        label: "Valuation",
        data: tab === "oneYear" ? oneYearData : fiveYearData,
        backgroundColor: "#023220",
        borderColor: "#023220",
        borderWidth: 1,
        tension: 0.3,
        type: "bar",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: { display: true },
      legend: { position: "top", display: true },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        ticks: { fontColor: "#c8ccdb" },
        barPercentage: 0.7,
        display: true,
        grid: { borderColor: "rgba(119, 119, 142, 0.2)" },
      },
      y: {
        beginAtZero: true,
        grid: { borderColor: "rgba(119, 119, 142, 0.2)" },
        ticks: {
          callback: (value) => value.toLocaleString(), // Format numbers with commas
        },
      },
    },
    interaction: { intersect: false },
  };

  return (
    <Line
      options={chartOptions}
      data={chartData}
      className="barchart chart-dropshadow2 ht-300 chartjs-render-monitor"
      height="100"
    />
  );
};

export default MainDashboard;
