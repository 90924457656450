import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import Select from "react-select";
import UserService from "../../../services/user.service";

const periodOptions = [
  { value: "month", label: "Month" },
  { value: "week", label: "Week" },
];

const weekOptions = [1, 2, 3].map((num) => ({ value: num, label: num }));

const Inactive = ({ show, onClose }) => {
  if (!show) return null;

  const handleSubmit = async (values) => {
    const payload = {
      timePeriodType: values?.periodType?.value,
      timePeriodValue: values?.weeks?.value,
      notificationTitle: values?.title,
      notificationBody: values?.message,
    };
    const response = await UserService?.inActiveUserNotification(payload);
    if (response) onClose();
  };

  return (
    <Modal show={show} onHide={onClose} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Set User Inactive</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            title: "",
            periodType: { value: "month", label: "Month" },
            weeks: { value: 1, label: 1 },
            message: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="title">
                <Form.Label>Title</Form.Label>
                <Field type="text" name="title" className="form-control" />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-danger"
                />
              </Form.Group>

              <Form.Group controlId="periodType">
                <Form.Label className="mt-3">Period Type</Form.Label>
                <Select
                  name="periodType"
                  options={periodOptions}
                  value={values.periodType}
                  onChange={(option) => {
                    setFieldValue("periodType", option);
                    if (option.value === "month") {
                      setFieldValue("weeks", { value: 1, label: 1 });
                    }
                  }}
                />
                <ErrorMessage
                  name="periodType"
                  component="div"
                  className="text-danger"
                />
              </Form.Group>

              {values.periodType.value === "week" && (
                <Form.Group controlId="weeks">
                  <Form.Label className="mt-3">Number of Weeks</Form.Label>
                  <Select
                    name="weeks"
                    options={weekOptions}
                    value={values.weeks}
                    onChange={(option) => setFieldValue("weeks", option)}
                  />
                  <ErrorMessage
                    name="weeks"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              )}

              <Form.Group controlId="message">
                <Form.Label className="mt-3">Message</Form.Label>
                <Field
                  as="textarea"
                  name="message"
                  className="form-control"
                  rows="4"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="text-danger"
                />
              </Form.Group>

              <div className="d-flex justify-content-end mt-3">
                <Button variant="secondary" onClick={onClose} className="me-2">
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default Inactive;
