import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Tab, Tabs } from "react-bootstrap";
import PropertiesService from "../services/properties.service";
import { CircularProgress } from "@mui/material";

export const RevenueChart = ({ propertyId }) => {
  const [data, setData] = useState([]);
  const [type, setType] = useState("month");
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  // Chart options
  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      display: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories,
    },
    /*  yaxis: {
      title: {
        text: "thousands",
      },
    }, */
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " thousands";
        },
      },
    },
  };

  // Fetch data from API
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await PropertiesService?.totalRevenueGraph(
        type,
        propertyId
      );
      if (response) {
        const result = response?.data?.data.slice(1); // Skip the first element if needed
        setData(result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Prepare chart data based on type
  const prepareChartData = () => {
    const rentalIncome = data.map((item) => item.rentalIncome);
    const totalOutward = data.map((item) => item.totalOutward);
    const netProfit = data.map((item) => item.netProfit);
    const categoryLabels = data.map((item) => item.startDate);

    setSeries([
      { name: "Rental Income", data: rentalIncome },
      { name: "Total Outward", data: totalOutward },
      { name: "Net Profit", data: netProfit },
    ]);
    setCategories(categoryLabels);
  };

  useEffect(() => {
    fetchData();
  }, [type]);

  useEffect(() => {
    if (data.length > 0) {
      prepareChartData();
    }
  }, [data]);

  return (
    <div className="bg-white rounded-10 p-4">
      {/* Chart component */}
      <div id="chart">
        {loading ? (
          <div
            style={{
              height: "350px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={350}
          />
        )}
      </div>
      {/* Tabs below the graph */}
      <div className="graph-tab">
        <Tabs
          defaultActiveKey="oneMonth"
          fill
          onSelect={(key) => setType(key === "oneMonth" ? "month" : "year")}
          className="nav panel-tabs main-nav-line justify-content-center"
        >
          <Tab
            className="border border-top-0 p-0"
            eventKey="oneMonth"
            title="One Month"
          />
          <Tab
            className="border border-top-0 p-0"
            eventKey="oneYear"
            title="One Year"
          />
        </Tabs>
      </div>
    </div>
  );
};
