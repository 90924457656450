import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import PropertiesService from "../../../services/properties.service";
import { enqueueSnackbar } from "notistack";

const ShareUnlockModal = ({
  show,
  handleClose,
  propertyId,
  handleOpenModal,
  lockShare,
  getPropertyWiseShareData,
  DetailsOfProperty,
}) => {
  const [shareOption, setShareOption] = useState("all");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      shareOption: { value: "all", label: "All Shares" },
      numberOfShares: "",
    },
    onSubmit: (values) => {
      if (values?.shareOption?.value === "number" && !values?.numberOfShares) {
        setError("Number of Shares is required");
        return;
      } else {
        setShowConfirmation(true);
        handleClose();
      }
    },
  });

  // Reset form values when modal is opened
  useEffect(() => {
    if (show) {
      // formik.resetForm();
      setShareOption("all");
      setError("");
    }
  }, [show]);

  const handleOptionChange = (selectedOption) => {
    setShareOption(selectedOption.value);
    setError("");
    formik.setFieldValue("shareOption", selectedOption);
  };

  const handleConfirm = async () => {
    const numberOfShares = formik?.values?.numberOfShares
      ? formik?.values?.numberOfShares
      : lockShare;
    try {
      const response = await PropertiesService?.unlockShares(
        propertyId,
        numberOfShares
      );
      if (response) {
        getPropertyWiseShareData();
        DetailsOfProperty()
        enqueueSnackbar(response.data?.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      setShowConfirmation(false);
      handleClose();
    }
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
    handleOpenModal();
  };

  const handleCloseFirst = () => {
    handleClose();
    formik.resetForm();
  };

  const shareOptions = [
    { value: "all", label: "All Shares" },
    { value: "number", label: "Number of Shares" },
  ];

  return (
    <>
      <Modal show={show} onHide={handleCloseFirst} centered>
        <Modal.Header closeButton>
          <Modal.Title>Unlock Shares</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="shareOption">
              <Form.Label>Select Option</Form.Label>
              <Select
                name="shareOption"
                options={shareOptions}
                value={formik.values.shareOption}
                onChange={handleOptionChange}
                onBlur={formik.handleBlur}
                className={
                  formik.touched.shareOption && formik.errors.shareOption
                    ? "is-invalid"
                    : ""
                }
              />
              {formik.touched.shareOption && formik.errors.shareOption && (
                <div className="invalid-feedback">
                  {formik.errors.shareOption.value}
                </div>
              )}
            </Form.Group>

            {formik.values.shareOption.value === "number" && (
              <Form.Group controlId="numberOfShares" className="mt-3">
                <Form.Label>Number of Shares</Form.Label>
                <Form.Control
                  type="number"
                  name="numberOfShares"
                  value={formik.values.numberOfShares}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.numberOfShares &&
                    formik.errors.numberOfShares
                  }
                />
                <p className="text-danger">{error}</p>
              </Form.Group>
            )}

            <div className="d-flex justify-content-end mt-4">
              <Button
                variant="secondary"
                onClick={handleCloseFirst}
                className="me-2"
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Render the confirmation modal separately */}
      <Modal show={showConfirmation} onHide={handleCancelConfirmation} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Unlock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to unlock the selected shares?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelConfirmation}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShareUnlockModal;
