import React from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import userDefault from "../../../../assets/img/svgs/userDefault.svg";

const TransfershareModal = (props) => {
  const { show, formik, loaderSubmit, transferData, handleCheck } = props;

  return (
    <>
      <Modal
        {...props}
        size="large"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Share transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="text-center"
          style={{ paddingBottom: "initial" }}
        >
          <div className="col-122">
            <div className="card custom-card">
              <form onSubmit={formik.handleSubmit}>
                <div
                  className="col-12"
                  style={{
                    overflow: "auto",
                    paddingTop: "15px",
                    marginBottom: "15px",
                    maxHeight: "650px",
                    padding: "0px 0px 0px 10px",
                  }}
                >
                  <div className="mb-3">
                    <img
                      src={
                        transferData?.currentownerprofile
                          ? transferData?.currentownerprofile
                          : userDefault
                      }
                      alt="profile"
                      width={100}
                      height={100}
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />{" "}
                    <div
                      className="font-weight-bold"
                      style={{ fontSize: "large" }}
                    >
                      {transferData?.currentownerusername}
                    </div>
                  </div>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {"From"}
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <Form.Control
                        value={transferData?.currentowneremail}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {"Property id"}
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <Form.Control value={transferData?.propertyid} disabled />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {"Share number"}
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <Form.Control
                        value={transferData?.sharenumber}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {"To"}
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <Form.Control
                        type="email"
                        name="to"
                        placeholder="email"
                        value={formik.values.to}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        autofill="false"
                      />
                      {formik.errors.to && formik.touched.to ? (
                        <p className="text-start error">{formik.errors.to}</p>
                      ) : null}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      ></Form.Label>
                    </Col>
                    <Col xs={8} className="d-flex">
                      <Form.Check
                        type="checkbox"
                        value={formik.values.withFees}
                        // checked={formik.vsalues.withFees}
                        onChange={(e) => handleCheck(e)}
                        label="With fees"
                      ></Form.Check>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    type="submit"
                    style={{
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "15px",
                      textAlign: "center",
                    }}
                  >
                    Submit
                    {/* {loaderSubmit ? <Spinner /> : "Submit"} */}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransfershareModal;
