import serverCall from "../../serverCall";

const GetAllTickets = async (pageNumber, pageSize, search) => {
  try {
    const response = await serverCall.get(
      `/support/admin/get-all-ticket?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`
    );
    return response;
  } catch (e) {
    console.log("e", e);
  }
};
const TicketAcion = async (id, status) => {
  try {
    const response = await serverCall.post(
      `/support/admin/ticket-action?ticketId=${id}&status=${status}`
    );
    return response;
  } catch (e) {
    console.log("e", e);
  }
};
const OneTicket = async (id, data) => {
  try {
    const response = await serverCall.get(
      `/support/admin/get-ticket?ticketId=${id}`,
      data
    );
    return response;
  } catch (e) {
    console.log("e", e);
  }
};
const SupportService = {
  GetAllTickets,
  TicketAcion,
  OneTicket,
};
export default SupportService;
