import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Arrow from "../../../assets/img/svgs/Arrow.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import ProfileService from "../../services/profile.service";
import { enqueueSnackbar } from "notistack";
import OtpInput from "otp-input-react";
import { IoMdEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const ProfileModal = (props) => {
  const { setIsprofile } = props;
  const [clickChng, setclickChng] = useState(false);
  const [isOtp, setisOtp] = useState(false);
  const [isconfirm, setIsconfirm] = useState(false);
  const [isMatch, setIsMatch] = useState(false);
  const [otp, setOtp] = useState("");
  const [emailfinal, setEmailfinal] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const email = localStorage.getItem("email");
  const userName = localStorage.getItem("userName");

  const initialValue = {
    email: "",
  };
  const initialValue2 = {
    password: "",
    newPassword: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Please enter the email"),
  });
  const validationSchema2 = Yup.object().shape({
    password: Yup.string().required("Please enter the password"),
    newPassword: Yup.string().required("Please enter the confirm password"),
  });
  const handleFormSubmit = async (values) => {
    const { email } = values;
    setEmailfinal(email);
    try {
      const response = await ProfileService.sendOtp(email);
      setisOtp(true);
      // setIsprofile(false);
      enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log("e", e);
      enqueueSnackbar(e?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  const handleVarify = async () => {
    try {
      const response = await ProfileService.otpVarify(emailfinal, otp);
      enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      setIsconfirm(true);
    } catch (e) {
      console.log("e", e);
      enqueueSnackbar(e?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  const handleFormSubmit2 = async (values, action) => {
    const { password, newPassword } = values;
    const passwordsMatch = password !== newPassword;

    setIsMatch(passwordsMatch);
    if (passwordsMatch) {
      setIsMatch(true);
      return;
    }
    try {
      const response = await ProfileService.changePassword({
        password: password,
        newPassword: newPassword,
      });
      localStorage.clear();
      navigate("/login");
      setIsconfirm(false);
      setisOtp(false);
      setclickChng(false);
      setIsprofile(false);
      enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log("e", e);
      enqueueSnackbar(e?.response?.data?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });
  const formik2 = useFormik({
    initialValues: initialValue2,
    validationSchema: validationSchema2,
    onSubmit: handleFormSubmit2,
  });
  const handlePwdchange = () => {
    setclickChng(true);
  };
  const getBackmain = () => {
    setclickChng(false);
    // formik.resetForm();
  };
  const getBack = () => {
    setisOtp(false);
  };
  const handleCLose = () => {
    props.onHide();
    formik.resetForm();
  };
  // useEffect(() => {
  //   if (formik2.values.newPassword !== "") {
  //     if (formik2.values.password !== formik2.values.newPassword) {
  //       setIsMatch(true);
  //     } else {
  //       setIsMatch(false);
  //     }
  //   }
  // }, [formik2.values]);

  return (
    <>
      <Modal
        {...props}
        size="large"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="text-center"
          style={{ paddingBottom: "initial" }}
        >
          <div className="col-122">
            <div className="card custom-card">
              <div
                className="col-12"
                style={{
                  overflow: "auto",
                  paddingTop: "15px",
                  maxHeight: "650px",
                  padding: "0px 0px 0px 10px",
                }}
              >
                {!clickChng ? (
                  <>
                    <Row className="mb-2">
                      <Col xs={4} className="d-flex align-items-center">
                        <Form.Label
                          className="fw-bold text-start"
                          style={{ color: "black", marginBottom: "0px" }}
                        >
                          {"User name"}
                        </Form.Label>
                      </Col>
                      <Col xs={8}>
                        <Form.Control value={userName} disabled />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={4} className="d-flex align-items-center">
                        <Form.Label
                          className="fw-bold text-start"
                          style={{ color: "black", marginBottom: "0px" }}
                        >
                          {"Email"}
                        </Form.Label>
                      </Col>
                      <Col xs={8}>
                        <Form.Control value={email} disabled />
                      </Col>
                    </Row>
                    <div className="d-flex flex-column align-items-end mt-3">
                      {/* <div
                          style={{ cursor: "pointer" }}
                          onClick={handlePwdchange}
                        >
                          <img src={Arrow} alt="image" width={30} />
                        </div>
                        <p>Do uoy want to change password?</p> */}
                      <Button
                        type="button"
                        className="btn btn-sm"
                        onClick={handlePwdchange}
                      >
                        Change password
                      </Button>
                    </div>
                  </>
                ) : !isOtp ? (
                  <>
                    <form onSubmit={formik.handleSubmit}>
                      <p>
                        Please, enter your email address. You will receive a
                        otp.
                      </p>
                      <Row className="mb-2">
                        <Col xs={4} className="d-flex align-items-center">
                          <Form.Label
                            className="fw-bold text-start"
                            style={{ color: "black", marginBottom: "0px" }}
                          >
                            {"Email"}
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                          {formik.errors.email && formik.touched.email ? (
                            <p className="text-start error">
                              {formik.errors.email}
                            </p>
                          ) : null}
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-between mt-3">
                        <Button
                          className="btn btn-sm"
                          type="button"
                          onClick={getBackmain}
                        >
                          Back
                        </Button>
                        <Button className="btn btn-sm" type="submit">
                          Get otp
                        </Button>
                      </div>
                    </form>
                  </>
                ) : !isconfirm ? (
                  <>
                    <p>Please, enter your otp. And create a new password.</p>
                    <OtpInput
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                      autoFocus
                      value={otp}
                      onChange={setOtp}
                      className="flex align-items-center justify-content-center"
                    />
                    <div className="d-flex justify-content-between mt-3">
                      <Button
                        className="btn btn-sm"
                        type="button"
                        onClick={getBack}
                      >
                        Back
                      </Button>
                      <Button
                        className="btn btn-sm"
                        type="button"
                        onClick={handleVarify}
                      >
                        Varify otp
                      </Button>
                    </div>
                  </>
                ) : (
                  <form onSubmit={formik2.handleSubmit}>
                    <Row className="mb-2">
                      <Col xs={4} className="d-flex align-items-center">
                        <Form.Label
                          className="fw-bold text-start"
                          style={{ color: "black", marginBottom: "0px" }}
                        >
                          {"New password"}
                        </Form.Label>
                      </Col>
                      <Col xs={8} style={{ position: "relative" }}>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          value={formik2.values.password}
                          onChange={formik2.handleChange}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "15px",
                          }}
                        >
                          <button
                            type="button"
                            style={{
                              padding: "0.375rem 0.5rem",
                              border: "aliceblue",
                            }}
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <IoIosEyeOff
                                style={{ height: "25px", width: "20px" }}
                              />
                            ) : (
                              // <FaEyeSlash style={{ fontSize: "1rem" }} />
                              <IoMdEye
                                style={{ height: "25px", width: "20px" }}
                              />
                              // <FaEye style={{ fontSize: "1rem" }} />
                            )}
                          </button>
                        </div>
                        {formik2.errors.password && formik2.touched.password ? (
                          <p className="text-start error">
                            {formik2.errors.password}
                          </p>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={4} className="d-flex align-items-center">
                        <Form.Label
                          className="fw-bold text-start"
                          style={{ color: "black", marginBottom: "0px" }}
                        >
                          {"Confirm password"}
                        </Form.Label>
                      </Col>
                      <Col xs={8}>
                        <Form.Control
                          type={showPassword2 ? "text" : "password"}
                          name="newPassword"
                          placeholder="New Password"
                          value={formik2.values.newPassword}
                          onChange={formik2.handleChange}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "15px",
                          }}
                        >
                          <button
                            type="button"
                            style={{
                              padding: "0.375rem 0.5rem",
                              border: "aliceblue",
                            }}
                            onClick={togglePasswordVisibility2}
                          >
                            {showPassword2 ? (
                              <IoIosEyeOff
                                style={{ height: "25px", width: "20px" }}
                              />
                            ) : (
                              // <FaEyeSlash style={{ fontSize: "1rem" }} />
                              <IoMdEye
                                style={{ height: "25px", width: "20px" }}
                              />
                              // <FaEye style={{ fontSize: "1rem" }} />
                            )}
                          </button>
                        </div>
                        {formik2.errors.newPassword &&
                        formik2.touched.newPassword ? (
                          <p className="text-start error">
                            {formik2.errors.newPassword}
                          </p>
                        ) : null}
                        {isMatch ? (
                          <p className="text-start error">
                            Password doesn't match
                          </p>
                        ) : null}
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end mt-3">
                      <Button className="btn btn-sm" type="submit">
                        Submit
                      </Button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileModal;
