import React, { Fragment, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import TermsPrivacyy from "../../../services/termsprivacyy.service";
import { useEffect } from "react";
import { Divider } from "antd";
import { enqueueSnackbar } from "notistack";
import withLoader from "../../../layout/loader/withLoader";

const TermsPrivacy = () => {
  const [termsOpen, setTermsOpen] = useState(false);
  const [isTermsErr, setIsTermsErr] = useState(false);
  const [isPrivacyErr, setIsPrivacyErr] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [loaderPrivacy, setLoaderPrivacy] = useState(false);
  const [loaderTerms, setLoaderTerms] = useState(false);
  const [termsUrl, setTermsUrl] = useState("");
  const [privacyUrl, setPrivacyUrl] = useState("");
  const [id, setId] = useState("");
  const inputTermRef = useRef();
  const inputPrivacyRef = useRef();

  const handleUpdateTerms = async () => {
    setTermsOpen(!termsOpen);
    if (!termsOpen) {
      setTimeout(() => {
        inputTermRef.current.focus();
      }, 0);
    } else {
      setLoaderTerms(true);
      const response = await TermsPrivacyy.updateTermsCondition({
        documentId: id,
        termsAndConditionsUrl: termsUrl,
      });
      if (termsUrl === "") {
        setIsTermsErr(true);
        return;
      }
      getUrls();
      setLoaderTerms(false);
      enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
  };
  const handleChangeTerms = (e) => {
    const input = e.target.value;
    setTermsUrl(input);
    if (input === "") {
      setIsTermsErr(true);
    } else {
      setIsTermsErr(false);
    }
  };
  const handleUpdatePrivacy = async () => {
    setPrivacyOpen(!privacyOpen);
    if (!privacyOpen) {
      setTimeout(() => {
        inputPrivacyRef.current.focus();
      }, 0);
    } else {
      setLoaderPrivacy(true);
      const response = await TermsPrivacyy.updatePrivacyPolicy({
        documentId: id,
        privacyPolicyUrl: privacyUrl,
      });
      if (privacyUrl === "") {
        setIsPrivacyErr(true);
        return;
      }
      getUrls();
      setLoaderPrivacy(false);
      enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  const handleChangePrivacy = (e) => {
    const input = e.target.value;
    if (input === "") {
      setIsPrivacyErr(true);
    } else {
      setIsPrivacyErr(false);
    }

    setPrivacyUrl(input);
  };
  const getUrls = async () => {
    const response = await TermsPrivacyy.getUrl();
    const result = response?.data?.data;
    console.log("result", response);
    setTermsUrl(result?.termsAndConditionsUrl);
    setPrivacyUrl(result?.privacyPolicyUrl);
    setId(result?.id);
  };
  useEffect(() => {
    getUrls();
  }, []);

  return (
    <>
      <Fragment>
        <Row className="row-sm mt-5 page-header">
          <Col xs={12} md={6} lg={6} xl={6}>
            <Card
              className="custom-card transcation-crypto"
              style={{ fullwidth: "100%", marginTop: "0px" }}
            >
              <Card.Body>
                <Row>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h2
                      className="main-content-title tx-24 mb-0"
                      style={{ marginBottom: "40px" }}
                    >
                      Terms-Conditions
                    </h2>
                    <Button
                      variant="primary"
                      type="button"
                      className="my-2 btn-icon-text"
                      onClick={() => {
                        handleUpdateTerms();
                      }}
                      disabled={isTermsErr}
                    >
                      {loaderTerms ? (
                        <Spinner />
                      ) : !termsOpen ? (
                        <>
                          <i className="fe fe-edit"></i> Update
                        </>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                  <FormGroup
                    className="form-group"
                    controlId="validationFormik101"
                  >
                    <Form.Control
                      type="text"
                      value={termsUrl}
                      onChange={(e) => handleChangeTerms(e)}
                      autoComplete="off"
                      autofill="false"
                      disabled={!termsOpen ? true : false}
                      ref={inputTermRef}
                    />
                    {isTermsErr && (
                      <p className="text-start error">{"Enter the URL!"}</p>
                    )}
                  </FormGroup>
                </Row>
                <Divider />
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h2
                    className="main-content-title tx-24 mb-0"
                    style={{ marginBottom: "40px" }}
                  >
                    Privacy-Policy
                  </h2>
                  <Button
                    variant="primary"
                    type="button"
                    className="my-2 btn-icon-text"
                    onClick={() => {
                      handleUpdatePrivacy();
                    }}
                    disabled={isPrivacyErr}
                  >
                    {loaderPrivacy ? (
                      <Spinner />
                    ) : !privacyOpen ? (
                      <>
                        <i className="fe fe-edit"></i> Update
                      </>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
                <FormGroup
                  className="form-group"
                  controlId="validationFormik101"
                >
                  <Form.Control
                    type="text"
                    value={privacyUrl}
                    onChange={(e) => handleChangePrivacy(e)}
                    autoComplete="off"
                    autofill="false"
                    disabled={!privacyOpen ? true : false}
                    ref={inputPrivacyRef}
                  />
                  {isPrivacyErr && (
                    <p className="text-start error">{"Enter the URL!"}</p>
                  )}
                </FormGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
    </>
  );
};

export default withLoader(TermsPrivacy);
